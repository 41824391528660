import React from 'react'
import Footer from '../Components/Footer/Footer'
import Branches from '../Components/Branches/Branches'
import { useEffect } from 'react'
import bannerimage from '../Components/Images/banner-image.png'
function Contact() {
  useEffect(()=>{
    document.title="Fissure Surgeon Near kopar khairane | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Find top Fissure Surgeon near kopar khairane. Expert care by Dr. Abhijit Bagul. Compassionate, skilled treatment for rectal fissures."
    )
    
  })
  return (
    <>
      <>
  <div className="page-wraper">

   
    {/* inner page banner */}
    <div
      className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
      style={{ backgroundImage: `url(${bannerimage})` }}
    >


      <div className="container">
        
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">Fissure Surgeon Near kopar khairane</h1>
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Contact</li>
            </ul>
          </div>
          {/* Breadcrumb row END */}
        </div>
      </div>
    </div>
    {/* inner page banner END */}
    {/* Main Slider */}
    <div
      className="section-full bg-white content-inner-2 "
      style={{ backgroundColor: "black", padding: "100px 0" }}
    >
      <div className="container">
        

        <div className="row">
          <div className="col-md-4 col-sm-4 offset-1">
            <div className="serviceBox">
              <div className="service-icon">
                <i className="fa fa-map" />
              </div>
              <h3 className="title text-center">
                Dr Bagul's Proctology Clinic
              </h3>
              <p className="description text-center">
                Plot 12-13, Sector 20, Kopar Khairane, Navi Mumbai, Maharashtra
                400709
              </p>
            </div>
          </div>
          <div className="col-md-2 col-sm-2 " />
          <div className="col-md-4 col-sm-4">
            <div className="serviceBox">
              <div className="service-icon">
                <i className="fa fa-phone" />
              </div>
              <h3 className="title text-center">Phone</h3>
              <p className="description text-center">
                +91-9820144713 <br />
              </p>
            </div>
          </div>
         
        </div>
        <div style={{display:'none'}}>
        <p className='mt-5'>
        Fissure Surgeon Near Kopar Khairane – Expert Care for Anal Fissures 

Are you searching for a fissure surgeon near Kopar Khairane? Look no further. Anal fissures can cause significant discomfort, and finding the right medical care is essential for a quick recovery. Our expert fissure surgeons in Kopar Khairane specialize in diagnosing and treating this condition with precision, offering patients effective solutions to regain their comfort and quality of life. 
        </p>
        <p>
          <h4>What is an Anal Fissure? </h4>
          An anal fissure is a small tear or cut in the lining of the anus, which can lead to severe pain during bowel movements. It is commonly caused by constipation, straining during bowel movements, or chronic diarrhea. While anal fissures can sometimes heal on their own, persistent fissures may require medical intervention, and that’s where our expert fissure surgeons step in. 
        </p>
        <p className=''>
          <h4>Why Choose Us?  </h4>
              <b>Experienced Fissure Surgeons 
              </b><br/>
              Our team consists of experienced and skilled fissure surgeons who provide personalized care for each patient. They have successfully treated numerous cases of anal fissures using both non-surgical and surgical techniques.
              <br/><b>Advanced Treatment Options 
              </b><br/>
              We use the latest techniques to ensure effective treatment of fissures. Whether you require medication, lifestyle changes, or surgery, our surgeons will tailor the treatment plan to your specific needs. 
              <br/><b>Compassionate Care  
              </b><br/>
              We understand how uncomfortable and painful anal fissures can be. Our team provides compassionate care and support throughout your treatment journey, ensuring your experience is as comfortable as possible. 
              <br/><b>Convenient Location 
              </b><br/>
              Located near Kopar Khairane, our clinic offers easy access to expert care. You don’t have to travel far to find relief from your fissure symptoms. 
        
        </p>
        <h4>Book Your Appointment Today </h4>
        <p>If you’re suffering from an anal fissure and need a fissure surgeon near Kopar Khairane, contact us today. We provide the highest level of care, helping you find relief and return to your daily life without discomfort. </p>
        <p><b>Get in touch</b> to schedule a consultation with our expert fissure surgeons! </p>
        </div>
      </div>
    </div>
   
  </div>
  <br />
  <br />
  {/* Footer */}
  <Branches/>
<Footer/>
  
  <div
    className="navbar-fixed-bottom d-lg-none d-xl-none d-md-none d-sm-none row "
    style={{
      textAlign: "center",
      position: "fixed",
      right: 0,
      bottom: 0,
      left: 0,
      padding: 4,
      backgroundColor: "lightgray",
      zIndex: 29
    }}
  >
    <div
      className="d-lg-none d-xl-none d-md-none d-sm-none col-6"
      style={{ backgroundColor: "#018f99", padding: 8 }}
    >
      <a
        href="tel:+91 9820144713"
        style={{ color: "white", fontSize: 18, textDecoration: "none" }}
      >
        <img
          src="https://uc.cyberrafting.com/tp/alana-institute/resources/assets/images/phone-icon.png"
          style={{ width: 32, textDecoration: "none" }}
          alt="Call"
        />{" "}
        Call Us
      </a>
    </div>
    <div
      className="d-lg-none d-xl-none d-md-none d-sm-none col-6"
      style={{ backgroundColor: "#10ba17", padding: 8 }}
    >
      <a
        href="https://api.whatsapp.com/send?phone=+91 9820144713  &text=Hi,%20I%20want%20to%20Book%20an%20Appointment.%20"
        style={{ color: "white", fontSize: 18, textDecoration: "none" }}
      >
        <img
          src="https://uc.cyberrafting.com/tp/alana-institute/resources/assets/images/whatsapp.png"
          style={{ width: 32 }}
          alt="Call"
        />
        WhatsApp
      </a>
    </div>
  </div>
  {/* whatsapp call btn code */}
  {/* JAVASCRIPT FILES ========================================= */}
  {/* JQUERY.MIN JS */}
  {/* WOW JS */}
  {/* BOOTSTRAP.MIN JS */}
  {/* BOOTSTRAP.MIN JS */}
  {/* FORM JS */}
  {/* FORM JS */}
  {/* MAGNIFIC POPUP JS */}
  {/* WAYPOINTS JS */}
  {/* COUNTERUP JS */}
  {/* IMAGESLOADED */}
  {/* MASONRY */}
  {/* MASONRY */}
  {/* OWL SLIDER */}
  {/* Rangeslider */}
  {/* CUSTOM FUCTIONS  */}
  {/* SORTCODE FUCTIONS  */}
  {/* CONTACT JS  */}
  <link
    rel="stylesheet"
    href="//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.css"
    media="screen"
  />
  {/* JQUERY.MIN JS */}
  <button className="scroltop fa fa-chevron-up" />
  <a
    href="https://1.envato.market/zObPW"
    target="_blank"
    className="bt-buy-now theme-btn"
  >
    <i className="ti-shopping-cart" />
    <span>Buy Now</span>
  </a>
  <a
    href="https://w3itexperts.ticksy.com"
    target="_blank"
    className="bt-support-now theme-btn"
  >
    <i className="ti-headphone-alt" />
    <span>Support</span>
  </a>
  {/* Go to www.addthis.com/dashboard to customize your tools */}
</>

    </>
  )
}

export default Contact
