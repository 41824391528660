// CarouselComponent.jsx

import React from 'react';
import './banner.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';
import bannerone from '../Images/bannerone.jpg'
import bannerthree from '../Images/bannerthree.jpg'

const Banner = () => {
    return (
        <div className="page-content bg-white">
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                navigation
                pagination={{ clickable: true }}
                loop
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                spaceBetween={0}
                slidesPerView={1}
                style={{ height: '100%' }} // Ensure the height adjusts as needed
            >
                <SwiperSlide>
                    <img
                        src={bannerone}
                        alt="advanced laser proctoloy treatment in navi mumbai"
                    />
                    <div className="carousel-caption banner-1">
                        <div className="row justify-content-start">
                            <div className="col-md-6">
                                <Link
                                    to="/best-piles-treatment-kopar-khairane"
                                    className="site-button outline pull-left"
                                >
                                    Know More
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <img
                        src="https://www.drabhijitbagul.com/img/slider/gastrointestinal-laparoscopy-surgery-navi-mumbai.jpg"
                        alt="gastrointestinal laparoscopy surgery in navi mumbai"
                    />
                    <div className="carousel-caption banner-2">
                        <div className="row justify-content-start">
                            <div className="col-md-6 text-left">
                                <h3
                                    style={{ marginBottom: 20, fontSize: 30 }}
                                    className="d-none d-sm-block"
                                >
                                    Treating Gastrointestinal Disorders With Laparoscopic Surgery
                                </h3>
                                <h3
                                    style={{ marginBottom: 10, fontSize: 13 }}
                                    className="d-lg-none d-xl-none d-md-none d-sm-none col-xs-12"
                                >
                                    Treating Gastrointestinal Disorders With Laparoscopic Surgery
                                </h3>
                                <ul className="padd-l" style={{ color: 'black' }}>
                                    <li> Laparoscopic Appendectomy / Appendix removal</li>
                                    <li> Laparoscopic Hernia Surgery</li>
                                    <li> Laparoscopic Cholecystectomy</li>
                                    <li> Advanced Endoscopy and Colonoscopy</li>
                                </ul>
                                <Link
                                    to="/service"
                                    className="site-button outline pull-left"
                                >
                                    Know More
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> */}
                <SwiperSlide>
                    <img
                        src={bannerthree}
                        alt="laser treatment for piles, fissure and fistula in navi mumbai"
                    />
                    <div className="carousel-caption">
                        <div className="row justify-content-start">
                            <div className="col-md-5 text-left">
                                <h1
                                    style={{ marginBottom: 20, fontSize: 30 }}
                                    className="d-none d-sm-block"
                                >
                                    Hernia Specialist Surgeon In Navi Mumbai
                                </h1>
                                <div>
                                    <h6 className="dg-motel">
                                        Why suffer in silence, when we offer you Painfree and no stitches laser treatment for piles, fissure and fistula
                                    </h6>
                                </div>
                                <Link
                                    to="/best-surgeon-for-piles-navi-mumbai"
                                    className="site-button outline pull-left"
                                >
                                    Know More
                                </Link>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Banner;
