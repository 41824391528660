import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import appendix from '../Components/Images/appendix.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Appendix() {
  useEffect(()=>{
    document.title="Appendicitis Surgeon In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Leading Appendicitis Surgeon in Navi Mumbai, Dr. Abhijit Bagul offers expert diagnosis and advanced surgical care for appendicitis patients."
    )
    
  })
  return (
    <>
     

     <div className="page-wraper">

 {/* inner page banner */}
 <div
   className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
   style={{ backgroundImage: `url(${bannerimage})` }}
 >
   <div className="container">
     <div className="dlab-bnr-inr-entry">
       <h1 className="text-white">Appendicitis Surgeon In Navi Mumbai </h1>
       {/* Breadcrumb row */}
       <div className="breadcrumb-row">
         <ul className="list-inline">
           <li>
             <a href="/">Home</a>
           </li>
           <li>Services</li>
           <li>Appendix Surgery</li>
         </ul>
       </div>
       {/* Breadcrumb row END */}
     </div>
   </div>
 </div>
 {/* inner page banner END */}
 {/* Main Slider */}
 <div
   className="section-full bg-white content-inner-2 "
   style={{ backgroundColor: "black" }}
 >
   <div className="container">
     <div className="row">
       <div className="col-md-8">
         <h2>Appendix Surgery</h2>
         <img
           src={appendix}
           alt="stitchless hernia in navi mumbai"
           className="img-thumbnail"
           width="100%"
         />
         <br />
         <br />
         <p>
         An appendectomy is a surgical procedure to remove the appendix, a small tube connected to your large intestine in the lower right side of your abdomen. The appendix has immune functions during early life, but if it becomes infected or inflamed, removal may be necessary.          </p>
         <p>
         Appendectomy is the standard treatment for appendicitis, where the appendix becomes inflamed, with or without pus. Regardless of whether the appendix is filled with pus or simply inflamed, the only definitive treatment is removal. Appendicitis pain resolves after the appendix is removed. If left untreated, the appendix can rupture, leading to a life-threatening infection called sepsis. This can occur within 48 to 72 hours of the onset of symptoms.          </p>
         <p>
         Because of this danger, appendicitis is considered a surgical emergency. The appendix is typically removed within 24 hours of diagnosis, as complications increase after this period. In some cases, if appendicitis is diagnosed late, the appendix may have already ruptured, leading to infection or abscess formation around it. Surgery is the only treatment in these situations, and the approach depends on the patient’s condition. 
         </p>
         {/* <p>
         The pain associated with appendicitis typically resolves once the appendix is removed. If left untreated, appendicitis can cause the appendix to rupture, releasing bacteria into the abdominal cavity and potentially leading to a life-threatening infection known as sepsis. This rupture can occur within 48 to 72 hours after appendicitis symptoms begin.
         </p>
         <p>
         Due to this risk, appendicitis is considered a medical emergency, and the appendix is generally removed within 24 hours of diagnosis. In some cases, if appendicitis is diagnosed too late and the appendix has already ruptured, resulting in infection or an abscess around the ruptured appendix, an Appendicitis Surgeon in Navi Mumbai may opt to treat the infection with antibiotics before performing an appendectomy a few weeks later.
         </p> */}
         {/* <p>
           In some cases where appendicitis is caught too late and the
           appendix has already ruptured — leading to infection or an abscess
           formation around the burst appendix — your medical team might
           decide to first treat the infection with antibiotics and then
           remove your appendix a few weeks later.
         </p> */}
         <h3>Appendicitis Surgeon In Navi Mumbai: Surgery Options </h3>
         <p>
         There are two types of appendectomy procedures: 
         </p>
         <ul className="padd-l">
           <li>
            {/* An */}
             <b>Open Appendectomy: </b>The surgeon makes a single 2- to 4-inch incision in the lower right abdomen to remove the appendix. The area is cleaned with sterile fluid, and the incision is closed. 


           </li>
           <li>
             <b>Laparoscopic Appendectomy: </b>This begins with three small incisions in the abdomen. Carbon dioxide is used to inflate the area, and a laparoscope is inserted to visualize the appendix. The surgeon proceeds with the removal, and the rest of the abdomen can be checked for any additional issues.
           </li>
         </ul>
         <p>
         In a second incision, your appendicitis surgeon in Navi Mumbai will introduce a laparoscope, which is a small tube with a light and camera that shows your abdomen on a video monitor. Your surgeon will then use surgical instruments to remove your appendix through the third incision, guided by the video monitor. Again, the operation concludes with cleaning the region and closing the incisions.
         </p>
         <p>
         If the appendix is ruptured or if there is peritonitis, open surgery may be required, especially if the small intestine is also affected. However, the laparoscopic procedure is preferred because it results in shorter hospital stays, less pain, quicker recovery, and fewer complications.          </p>
         {/* <p>
         The laparoscopic surgery typically leads to a shorter hospital stay, less pain, faster healing, and fewer complications, such as surgical infections. According to a survey published in April 2018 in the World Journal of Emergency Surgery, most surgeons globally prefer the laparoscopic technique over open surgery for both uncomplicated and difficult acute appendicitis cases. However, it is not yet universally accepted as the "gold standard," because it takes longer to do, increases the risk of intra-abdominal abscess, and costs more than an open appendectomy.
         </p> */}


      <h3>When Open Surgery May Be Preferred by an Appendicitis Surgeon In Navi Mumbai </h3>
         <p>In certain cases, your surgeon may opt for open surgery if you have: </p>
         <ul className="padd-l">
           <li>
           Severe abdominal swelling 
           </li>
           <li>A ruptured appendix </li>
           <li>Peritonitis or abscess </li>
           <li>Past multiple abdominal surgeries </li>
           <li>Obesity </li>
           <li>Pregnancy </li>
           <li>If the patient is having particularly contraindication for laparoscopic surgery due to their overall medical health  </li>

         </ul>
       </div>
     </div>
   </div>
   {/* /card */}
 </div>
</div>
<br />
<br />
 <Footer/>
</>

 
  )
}

export default Appendix
