import React from 'react'
import { Link } from 'react-router-dom'
import abouthomeone from '../Images/abouthomeone.jpg'

function AboutHome() {
  return (
    <>
      <div
  className="section-full bg-white content-inner-2 "
  style={{ backgroundColor: "black" }}
>
  <div className="container">
    <div className="row">
      <div className="col-md-7">
        <div className="section-head text-black">
          <h2 className="text-primary m-b10" style={{ marginTop: 30 }}>
            About DR. ABHIJIT BAGUL
          </h2>
          <h6 className="m-b10">
            MS, FIAGES, FMAS, FCPS (Surgery) FICS (USA)
            <br />
            Consultant Gastrointestinal &amp; laproscopic Surgeon
          </h6>
          <div className="col-md-5 d-block d-sm-none d-none d-sm-block d-md-none">
            <img
              width={300}
              height={300}
              src={abouthomeone}
              alt="Dr Abhijit Bagul - Gastrointestinal & Laproscopic Surgeon in Navi Mumbai"
              className=" img-wd"
            />
          </div>
          <p>
          Dr. Abhijit G. Bagul, a Hernia Specialist Surgeon in Navi Mumbai, is a distinguished proctologist, colorectal surgeon, and laser surgeon. He holds an MS in Surgery from Sir J.J. Group of Hospitals, Mumbai, and has completed his MBBS at the same institution. Dr. Bagul has been awarded a Fellowship in Minimal Access Surgery (FMAS) and a fellowship from the renowned KEM Hospital, Mumbai, under the aegis of the Indian Association of Gastrointestinal Endosurgeons.
          </p>
          <p>
          Dr. Bagul’s contributions to medical literature are notable, with two of his study publications recognized among the world's most exceptional cases. His expertise extends to community service, where he played a vital role in disaster relief during the Bhuj (Gujarat) earthquake, treating multiple casualties on-site. He has also been instrumental in organizing numerous surgical camps, providing essential medical care to patients from low-income or impoverished backgrounds. As a Consultant Gastrointestinal & Laparoscopic Surgeon, Dr. Bagul's commitment to patient care and surgical excellence marks him as a leading hernia specialist surgeon in Navi Mumbai.
          </p>
          <div className="text-center">
            <Link to="/best-fistula-surgery-doctor-near-navi-mumbai" className="site-button outline pull-left">
              Read More
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-5 d-none d-sm-block d-sm-none d-md-block">
        <img
          width={300}
          height={300}
          src={abouthomeone}
          alt="Dr Abhijit Bagul - Gastrointestinal & Laproscopic Surgeon in Navi Mumbai"
          className=" img-wd"
        />
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default AboutHome
