import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import herniasurgery from '../Components/Images/hernia-surgery.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Services() {
  useEffect(()=>{
    document.title="Hernia Treatment In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Hernia Treatment In Navi Mumbai by Dr. Abhijit Bagul offers expert, personalized care and advanced surgical solutions for hernia issues."
    )
    
  })
  return (
    <>
      <div
  className="dlab-bnr-inr overlay-primary __web-inspector-hide-shortcut__"
  style={{ backgroundImage: `url(${bannerimage})` }}
>
  <div className="container">
    <div className="dlab-bnr-inr-entry">
      <h1 className="text-white">Hernia Treatment In Navi Mumbai </h1>
      {/* Breadcrumb row */}
      <div className="breadcrumb-row">
        <ul className="list-inline">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Services</li>
          <li>Hernia Surgery</li>
        </ul>
      </div>
      {/* Breadcrumb row END */}
    </div>
  </div>
</div>


<section>
    <div className="container">
    <div
  className="section-full bg-white content-inner-2 "
  style={{ backgroundColor: "black" }}
>
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <h2>Hernia Surgery</h2>
        <img
          src={herniasurgery}
          alt="hernia surgery & treatment in navi mumbai"
          className="img-thumbnail"
          width="100%"
        />
        <br />
        <br />
        <p>
          A hernia is a bulge or swelling that happens as an inner body part
          pokes through a weak area of muscle or tissue wall.
        </p>
        <h3>What is a hernia? </h3>
        <p>
        A hernia is a bulge or swelling that occurs when an internal body part pushes through a weak area of muscle or tissue wall. Most frequently, hernias happen when an organ or tissue protrudes through a weakness in the abdominal muscle wall. People often experience minimal symptoms, but they may notice a swelling or lump in the stomach area or groin. In some cases, hernias can cause sudden pain, especially if the hernia becomes trapped or pinched, leading to more serious complications such as obstruction or strangulation. If this occurs, it's important to seek urgent medical care. Hernias generally don't heal on their own, and surgery is often required to repair the defect.         </p>
        {/* <p>
        If you experience sudden pain from your hernia, especially if it cannot be pushed back into place, seek emergency medical attention. This could indicate strangulation, cutting off blood flow to your intestines and abdominal tissues, or that your hernia is stuck or tightly constricted where it protrudes through the muscle wall.
        </p>
        <p>
        While a hernia is typically not dangerous, it cannot be treated without hernia repair. For expert hernia treatment in Navi Mumbai, seek medical consultation to discuss the best course of action for your condition
        </p> */}
        {/* <p>
          A hernia is not usually a serious condition but it will not go away
          without hernia treatment known as a hernia repair.
        </p> */}
        <h3>What does the hernia surgery involve?</h3>
        <p>
        Hernia Treatment in Navi Mumbai involves surgical repair that is performed by experienced surgeons. Surgery relieves pain, returns the organs to their correct place, and strengthens the weak muscle area. There are two main types of surgery:         </p>
        {/* <p>
        When performed as a day case, hernia treatment typically lasts one hour. It can be carried out by:
         
        </p> */}
        <ul className="padd-l">
          <li>
          During <b>Open surgery –</b>Involves an incision of around 2.5 to 3 inches near the hernia. The hernia is pushed back into the abdomen, and the incision is either stitched or a mesh is applied to reinforce the weakened area.
          </li>
          <li>

            
            <b>Keyhole (laparoscopic) surgery –</b>Less invasive, involves several smaller incisions, and uses special instruments, including a tiny camera, to repair the hernia. A mesh is also applied to prevent recurrence. 
          </li>
        </ul>
        <p>
        Your surgeon will decide which method is most appropriate based on the hernia's location and severity.         </p>
        <h3>Are there different types of hernia?</h3>
        {/* <p>
        Abdominal hernias are the most common type, typically occurring in areas of weakness in the abdominal wall such as the upper stomach, belly button, groin, and surgical scars. If you are seeking Hernia Treatment in Navi Mumbai, it's important to understand the different types of hernias that are frequently seen:
        </p> */}
        <p>The most frequently seen types of hernia include:</p>
        <ul className="padd-l">
          <li>
            {" "}
            - Inguinal Hernias: The most common type, seen mostly in men, causing a bulge in the groin and sometimes extending to the scrotum. 
          </li>
          <li>
          - Femoral Hernias: A rare type, more common in women, also affecting the groin area. 
          </li>
          <li>
          - Hiatus Hernias: Occur in the chest area when part of the stomach pushes up through the diaphragm. 
          </li>
          <li>
            {" "}
            - Umbilical/Periumbilical Hernias: Occur near the belly button due to a weakness in the abdominal wall. 
          </li>
          <li>
          - Incisional Hernias: Result from past surgery, where the scar tissue forms a weak spot. 
          </li>
          
            <p>
          For Hernia Treatment in Navi Mumbai, all types of hernias can be treated based on their location and severity.          
          </p>
        </ul>
        <h3>What complication can happen after the hernia surgery?</h3>
        <p>
        While hernia surgery is generally safe, complications can still arise. Common risks include hernia recurrence, fluid build-up (seroma), difficulty urinating, damage to nearby organs, wound infections, and mesh infection. Discuss these risks with your surgeon to understand what to expect during recovery.         </p>
        {/* <p>
        The biggest risk of complications is that the hernia may recur. Additional adverse effects of hernia surgery include seroma accumulation, or the formation of a fluid-filled sac beneath the skin's surface, difficulty or incapacity to urinate, injury to organs or tissues, wound infection, and mesh rejection.
        </p> */}
        <h3>What is the cost of the hernia surgery?</h3>
        <p>
        If you're seeking Hernia Treatment in Navi Mumbai, many clinics offer all-inclusive Total Care packages for a single payment, ensuring complete access to all necessary treatment. Payment options, including finance, are also available. For those with health insurance, hernia surgery is often covered, but it’s important to confirm with your insurance provider beforehand. 
        </p>
        {/* <p>
        Your health insurance may pay for a hernia procedure. Before starting treatment, we encourage you to confirm directly with your insurance company and obtain a written confirmation.
        </p> */}
        <h3>Recovery Time After Hernia Surgery </h3>
        <p>
        Recovery after hernia surgery typically takes 2 to 3 weeks. Patients undergoing laparoscopic surgery usually recover faster, often returning to work within a week. On the other hand, open surgery may take longer to heal, depending on the patient’s pain level and the location of the hernia. 
        </p>
        {/* <p>
        As part of your aftercare package, you will receive sixty days of post-discharge outpatient treatment, starting on the day you leave. We will also schedule a follow-up appointment with your surgeon to discuss the status of your hernia.
        </p> */}
      </div>
    </div>
  </div>
  {/* /card */}
</div>

    </div>
</section>

<Footer/>
    </>
  )
}

export default Services
