import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import bannerimage from '../Components/Images/banner-image.png'
import smile from '../Components/Images/smile.png'
import logo from '../Components/Images/dr-abhijit-logo.png'

function Testimonial() {
  useEffect(()=>{
    document.title="Best Gastroenterologist In Vashi | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul, the Best Gastroenterologist in Vashi, offers expert care and compassionate treatment for gastrointestinal issues."
    )
    
  })
  return (
    <>
      <>
  <div className="page-wraper">
    <div className="page-wraper">
    
      <nav className="navbar navbar-expand-md d-lg-none d-md-none d-block">
        <a href="/" className="dez-page">
          <img
            // src="img/dr-abhijit-logo-1.png"
            src={logo}
            alt="Dr Abhijit Bagul logo - Gastrointestinal & Laproscopic Surgeon in Navi Mumbai"
            style={{ width: 215 }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className="collapse navbar-collapse sidebar is-hidden"
          id="collapsibleNavbar"
        >
          <ul className="navbar-nav mob-nav">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="about.php">
                About Us
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown"
              >
                Services
              </a>
              <div
                className="dropdown-menu"
                style={{
                  background: "unset !important",
                  border: "unset !important"
                }}
              >
                <a href="hernia-surgery.php" className="dropdown-item">
                  Hernia Surgery
                </a>
                <a href="appendix-surgery.php" className="dropdown-item">
                  Appendix Surgery / Appendectomy
                </a>
                <a
                  href="gall-bladder-removal-surgery.php"
                  className="dropdown-item"
                >
                  Gall Bladder Removal Surgery (Cholecystectomy)
                </a>
                <a href="diabetic-foot.php" className="dropdown-item">
                  Diabetic Foot
                </a>
                <a href="circumcision.php" className="dropdown-item">
                  Circumcision / Male Genital Mutilation (MGM)
                </a>
                <a href="piles-surgery.php" className="dropdown-item">
                  Piles Surgery
                </a>
                <a href="stitch-less-hernia.php" className="dropdown-item">
                  Stitch-less Hernia
                </a>
                <a href="breast-lump.php" className="dropdown-item">
                  Breast Lump Removal (Lumpectomy)
                </a>
                <a href="laser-surgery-for-piles.php" className="dropdown-item">
                  Laser surgery for Piles, Fissure and Fistula
                </a>
                <a href="hydrocele-treatment.php" className="dropdown-item">
                  Hydrocele Treatment
                </a>
                <a href="pilonidal-sinus.php" className="dropdown-item">
                  Pilonidal Sinus
                </a>
                <a href="laser-proctology.php" className="dropdown-item">
                  Advanced Laser Proctology
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbardrop"
                data-toggle="dropdown"
              >
                Gallery
              </a>
              <div
                className="dropdown-menu"
                style={{
                  background: "unset !important",
                  border: "unset !important"
                }}
              >
                <a href="surgical-archieve.php" className="dropdown-item">
                  Surgical Archieve
                </a>
                <a href="media.php" className="dropdown-item">
                  Media
                </a>
                <a href="video.php" className="dropdown-item">
                  Videos
                </a>
              </div>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="testimonial.php">
                Testimonials
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="faqs.php">
                FAQ's
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://drabhijitbagul.com/blog/">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="contact.php">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* inner page banner */}
      <div
        className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
        style={{ backgroundImage: `url(${bannerimage})` }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Best Gastroenterologist In Vashi </h1>
            {/* Breadcrumb row */}
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Testimonials</li>
              </ul>
            </div>
            {/* Breadcrumb row END */}
          </div>
        </div>
      </div>
      {/* inner page banner END */}
      {/* Page Content */}
      <div className="container page-top sec-review">
        <br />
        <br />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                I had my gallstone removal surgery very recently by Dr Abhijit
                Bagul. I was deep pain and the surgery was critical but with his
                experience and precision the whole process went of smoothly and
                I was walking without any help the very next day. I have seen
                many who had pain and discomfort after the surgery. But I have
                started walking, playing sports and doing household chores quite
                smoothly. Highly recommended if you have similar problem and
                looking for the best surgeon. He is definitely the one
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">Bani Dey</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                Dr. Abhijit is very efficient, kind and friendly. I had to
                undergo gall bladder removal surgery n he is the one who handled
                the procedure. Extremely satisfied and happy. Overall nice
                experience.
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">manu suresh</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                Dr Abhijit Bagul is an excellent and experienced doctor. I was
                referred to him for a fitsula (Abscess) and my experience during
                the entire treatment (and surgery) was very good. He makes you
                understand the problem, suggest the best possible treatment and
                is very professional. He personally gets involved, during the
                entire process and also give proper instructions, guidance for
                post operation care procedures. I would recommend him and rate
                him as one of the best surgeons in Mumbai.
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">somesh surana</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                Dr. Abhijit baghul treated my dad for acute pancreatitis along
                with gall stones while my dad was covid positive. I must say he
                is the best surgeon and a kind human being. The way he handled
                the case and also he was giving me every update and explained me
                all the management and prognosis from time to time which helped
                me reduce my stress. He operated my dad ( Laproscopic
                cholecystectomy) its a laparoscopic procedure for removal of
                gall stones along with gall bladder. my dad is doing great and
                has completely recovered from the illness.I definitely would
                recommend him to everyone who is looking for a great surgeon.
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">Dr. Sarfaraz Shaikh</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                I have gone to dr for a lump surgery. I was very much tensed as
                l had no symptoms but still had some abnormalities in scanning.
                Dr explained everything very well and advised to go for a
                surgery. Everything went well . Really appreciate his friendly
                behaviour and clarity in explaining things. I always believe
                that God resides in the finger tips of surgeons and its really
                true in the case of Dr. Abhijit bagul. He is very much
                approachable.Even my mom had undergone small bowel surgery under
                him.Thanks to all support he had done even at that time.
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">suni nair</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                Your outstanding skills as a surgeon were in the right place at
                the right time, Thank you for everything you have done for me!
                You are a wonderful, caring doctor who was always friendly and
                comforting to your patients.
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">suvarna mahale</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                Dr Abhijit Bagul is *God's gift to mankind* in the true sense.
                He operated on me for Lipoma on the neck and the fear prevailing
                earlier just vanished by his miraculous touch and cure. He is
                not only a highly vigilant capable and efficient Surgeon but
                also a wonderful human being. Courteous, Caring, Selfless,
                Loving, Skillful, Inspiring and Encouraging personality. I shall
                always be indebted to him and always stand by him whenever he
                needs. He went out of his way to ensure that i was well looked
                after and thus recovered soon due to his care and guidance.
                <br />
                If you are looking for a Surgeon, Dr Abhijit is the safest Haven
                to mankind. I wish him the very best in life. Thanks Doc!
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">Ami Tonk</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12 thumb">
            <div className="review-wrap">
              <p>
                Dr. Abhijit Bagul... I cannot thank enough for your efforts
                towards my well being. You did magic.
                <br />
                Before going to OT we didn’t do a background check or google
                search about the doctor. But I met a real human being out there
                who is extremely skill full at his job. He gave my life back,
                with extreme positive energy. He touched my organs with
                humanity, with love not just as a Surgeon. He transferred his
                positive energy to my internal organs. This helped me recover so
                fast that everyone around me was surprised including him. Love
                you doc. Keep it up.
                <br />
                Let me know if you need me anytime, even in the OT😇. I’ll be
                there with the same positive energy.
                <br />
                You are the rock star....🤗🤗
              </p>
              <img src={smile}  alt='img'/>
              <div id="image-caption">Anjan Panda</div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <a
            href="https://www.google.com/search?q=Dr+Abhijit+Bagul+-+Best+Piles+Surgeon%2C+Hernia+Surgeon+%26+Laparoscopic+Surgeon+in+Navi+Mumbai%2C+Appendix+Treatment%2C+Gall+Bladder+Removal+Treatment%2C+Stitch-less+Hernia+in+Mumbai%2C+India&sxsrf=ALeKk01uVL8tR2AGeJACUe89YFNy6lU0uw%3A1626171543509&ei=l2jtYMu3HoWx8QPJ-I6oCQ&oq=Dr+Abhijit+Bagul+-+Best+Piles+Surgeon%2C+Hernia+Surgeon+%26+Laparoscopic+Surgeon+in+Navi+Mumbai%2C+Appendix+Treatment%2C+Gall+Bladder+Removal+Treatment%2C+Stitch-less+Hernia+in+Mumbai%2C+India&gs_lcp=Cgdnd3Mtd2l6EAMyBwgjELADECdKBAhBGAFQhAdYhAdg_AtoAHAAeACAAawEiAGcCpIBBzMtMS4xLjGYAQCgAQKgAQGqAQdnd3Mtd2l6yAEBwAEB&sclient=gws-wiz&ved=0ahUKEwiL38aJ6d_xAhWFWHwKHUm8A5UQ4dUDCA4&uact=5#lrd=0x3be7bf129622ebb5:0x79a81e668b67b8b,1,,,"
            className="site-button outline pull-left"
          >
            View All Reviews
          </a>
        </div>
        <div className='mt-5' style={{display:'none'}}>
        <b >Best Gastroenterologist in Vashi – Expert Care for Digestive Health </b>
        <p>
        Are you searching for the best gastroenterologist in Vashi? Look no further! Our clinic is dedicated to providing exceptional care for all your digestive health concerns. With years of experience and a team of highly skilled specialists, we are committed to helping you achieve optimal digestive health. 
        </p>
        <h4>
        Comprehensive Gastroenterology Services 
        </h4>
        <p>
        Our top-rated gastroenterologists in Vashi specialize in diagnosing and treating a wide range of digestive disorders. Whether you’re dealing with acid reflux, irritable bowel syndrome (IBS), liver diseases, or complex conditions like Crohn's disease or ulcerative colitis, we offer personalized treatment plans tailored to your needs. 
        </p>
        <p>
        We provide advanced diagnostic tools, including endoscopy and colonoscopy, ensuring accurate detection and effective treatment. Our state-of-the-art facility and compassionate staff make us the go-to choice for those seeking the best gastroenterological care in Vashi. 
        </p>
        <h4>Why Choose Us? </h4>
        <ul>
          <li>
            <b>Experienced Specialists:</b>
            <p>Our team is led by the best gastroenterologist in Vashi, known for their expertise and successful treatment outcomes.</p>
          </li>
          <li>
            <b>Patient-Centered Approach:</b>
            <p>We focus on understanding your health concerns and offer treatments that ensure both short-term relief and long-term health. </p>
          </li>
          <li>
            <b>Advanced Technology:</b>
            <p>We use the latest diagnostic tools to provide comprehensive care, ensuring accurate diagnoses and effective treatments.</p>
          </li>
          <li>
            <b>Personalized Care:</b>
            <p>Each patient receives a customized treatment plan designed to meet their specific health needs. </p>
          </li>
        </ul>
        <h4>Book Your Appointment Today </h4>
        <p>
        Don’t let digestive issues disrupt your life. If you’re searching for the best gastroenterologist in Vashi, schedule a consultation with us today. Our team is dedicated to providing compassionate and expert care that helps you live a healthier, more comfortable life. 
        </p>
        <p>
        For more information or to book your appointment with the best gastroenterologist in Vashi, contact us now. We are here to guide you on your journey to optimal digestive health. 
        </p>
        </div>
        <br />

        <br />
      </div>
     

     <Footer/>
      {/* JAVASCRIPT FILES ========================================= */}
      {/*<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">*/}
      {/**/}
      {/**/}
      {/**/}
      {/* whatsapp call btn code */}
      <div
        className="navbar-fixed-bottom d-lg-none d-xl-none d-md-none d-sm-none row "
        style={{
          textAlign: "center",
          position: "fixed",
          right: 0,
          bottom: 0,
          left: 0,
          padding: 4,
          backgroundColor: "lightgray",
          zIndex: 29
        }}
      >
        <div
          className="d-lg-none d-xl-none d-md-none d-sm-none col-6"
          style={{ backgroundColor: "#018f99", padding: 8 }}
        >
          <a
            href="tel:+91 9820144713"
            style={{ color: "white", fontSize: 18, textDecoration: "none" }}
          >
            <img
              src="https://uc.cyberrafting.com/tp/alana-institute/resources/assets/images/phone-icon.png"
              style={{ width: 32, textDecoration: "none" }}
              alt="Call"
            />{" "}
            Call Us
          </a>
        </div>
        <div
          className="d-lg-none d-xl-none d-md-none d-sm-none col-6"
          style={{ backgroundColor: "#10ba17", padding: 8 }}
        >
          <a
            href="https://api.whatsapp.com/send?phone=+91 9820144713  &text=Hi,%20I%20want%20to%20Book%20an%20Appointment.%20"
            style={{ color: "white", fontSize: 18, textDecoration: "none" }}
          >
            <img
              src="https://uc.cyberrafting.com/tp/alana-institute/resources/assets/images/whatsapp.png"
              style={{ width: 32 }}
              alt="Call"
            />
            WhatsApp
          </a>
        </div>
      </div>
     
      <link
        rel="stylesheet"
        href="//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.css"
        media="screen"
      />
      {/* JQUERY.MIN JS */}
      <button className="scroltop fa fa-chevron-up" />
    </div>
  </div>
  <a
    href="https://1.envato.market/zObPW"
    target="_blank"
    className="bt-buy-now theme-btn"
  >
    <i className="ti-shopping-cart" />
    <span>Buy Now</span>
  </a>
  <a
    href="https://w3itexperts.ticksy.com"
    target="_blank"
    className="bt-support-now theme-btn"
  >
    <i className="ti-headphone-alt" />
    <span>Support</span>
  </a>
  {/* Go to www.addthis.com/dashboard to customize your tools */}
</>

    </>
  )
}

export default Testimonial
