import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import breastlump from '../Components/Images/breast-lump.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function BrestPump() {
  useEffect(()=>{
    document.title="Lump Removal Surgeon In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul specializes as a renowned Lump Removal Surgeon in Navi Mumbai, offering expert surgical solutions with precision and care."
    )
    
  })
  return (
<>
  <div className="page-wraper">

    <div
      className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
      style={{ backgroundImage: `url(${bannerimage})` }}
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">Lump Removal Surgeon In Navi Mumbai </h1>
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Services</li>
              <li>Breast Lump</li>
            </ul>
          </div>
          {/* Breadcrumb row END */}
        </div>
      </div>
    </div>
    {/* inner page banner END */}
    {/* Main Slider */}
    <div
      className="section-full bg-white content-inner-2 "
      style={{ backgroundColor: "black" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2>Breast Lump</h2>
            <img
              src={breastlump}
              alt="breast lump removal (lumpectomy) in navi mumbai"
              className="img-thumbnail"
              width="100%"
            />
            <br />
            <br />
            <p>
            Breast lump removal is the surgical removal of a cancerous lump inside the breast. It’s also known as a lumpectomy. 
            </p>
            <p>
            A biopsy can show if a lump in the breast is cancerous. The goal of the procedure is to remove the lump and some healthy tissue around the tumor. When your doctor removes the healthy tissue along with the lump, it will help ensure that all cancer cells are gone. 
            </p>
            <p>
            A mastectomy may also be done, which is a complete surgical removal of the breast. Evidence shows that a lumpectomy is just as effective as a mastectomy in the early stages of breast cancer. 
            </p>
            <h3>Why a breast lump removal is performed </h3>
            <p>
            Breast lump removal is performed to prevent a cancerous tumor from spreading to other parts of your body. Whether a doctor can perform a lumpectomy depends on the size and stage of the tumor and certain patient characteristics, such as the size of your breast. 
            </p>
            <p>
            Many doctors prefer this method over a mastectomy. A lumpectomy is less invasive than the entire removal of the breast. In a lumpectomy, your doctor takes a part of the breast, which leaves much of your breast’s appearance and sensations intact. This allows for better breast symmetry. But you may need radiation or chemotherapy following a lumpectomy to ensure all cancer cells have been destroyed. 
            </p>
            <h3>How a breast lump removal is performed </h3>
            <p>
            Before surgery, you’ll change into a hospital gown and be given anesthesia. If local anesthesia is used, you may be given a sedative to relax during the breast lump removal. If you’re given general anesthesia, you’ll be in a painless sleep during the entire procedure. 
            </p>
            <p>
            Your surgeon will begin by locating the tumor. During your biopsy, your surgeon may have placed a metal marker or clip near the site. If that’s the case, a thin wire will be used to locate the clip. This wire helps guide your surgeon to the right spot for the incision. 
            </p>
            <p>
            Your surgeon will remove the tumor and some healthy cells around the tumor. This ensures that the entire tumor is removed. The lump is then sent to a laboratory for testing. 
            </p>
            <p>
            During surgery, your Lump Removal Surgeon In Navi Mumbai may remove lymph nodes from under your arm on the side of your breast. They will be tested to see if the cancer has spread. 
            </p>
            <p>
            If you are considering a breast lump removal, it’s important to consult with a qualified Lump Removal Surgeon In Navi Mumbai who can guide you through the process and discuss your options. After the procedure, follow-up care is essential to monitor recovery and ensure no further treatment is necessary. 
            </p>
            <p>
            Choosing the right Lump Removal Surgeon In Navi Mumbai can make a significant difference in your treatment experience and outcomes. 
            </p>
          </div>
        </div>
      </div>
      {/* /card */}
    </div>
  </div>
  <br />
  <br />
  
  <Footer/>
  
</>

  )
}

export default BrestPump
