import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbarr from './Components/Header/Navbarr';
import Home from './Components/Home/Home';
import About from './Page/About';
import Testimonial from './Page/Testimonial';
import Contact from './Page/Contact';
import Faq from './Page/Faq';
import GallerySurgical from './Page/GallerySurgical';
import SurgicalMedia from './Page/SurgicalMedia';
import Media from './Page/Media';
import Services from './Page/Services';
import Appendix from './Page/Appendix';
import GallBlader from './Page/GallBlader';
import Diabtic from './Page/Diabtic';
import Circumsion from './Page/Circumsion';
import Piles from './Page/Piles';
import Stitch from './Page/Stitch';
import BrestPump from './Page/BrestPump';
import LasurSur from './Page/LasurSur';
import Hydrocele from './Page/Hydrocele';
import Pilon from './Page/Pilon';
import Advance from './Page/Advance';
import Blog from './Page/Blog';
import AdvanceGl from './Page/AdvancedGI';


function App() {
  return (
  <>
  <Navbarr/>
  <Routes>
    <Route  path="/" element={<Home/>}/>
    <Route path="/best-fistula-surgery-doctor-near-navi-mumbai" element={<About/>}/>
    <Route path='/best-gastroenterologist-vashi' element={<Testimonial/>}/>
    <Route path='/piles-specialist-doctor-navi-mumbai' element={<Blog/>}/>
    <Route path='/advanced-laparoscopic-surgeon-navi-mumbai' element={<AdvanceGl/>}/>
    <Route path='/fissure-surgeon-near-kopar-khairane' element={<Contact/>}/>
    <Route path='/best-hernia-surgeon-navi-mumbai' element={<Faq/>}/>
    <Route path='/SurgicalArchieve' element={<GallerySurgical/>}/>
    <Route path='/best-proctologist-navi-mumbai' element={<SurgicalMedia/>} />
    <Route path="/laser-surgeon-for-piles-navi-mumbai" element={<Media/>}/>
    <Route path='/hernia-treatment-navi-mumbai' element={<Services/>}/>
    <Route path="/appendicitis-surgeon-navi-mumbai" element={<Appendix/>}/>
    <Route path='/gall-bladder-stone-treatment-vashi' element={<GallBlader/>}/>
    <Route path='/diabetic-foot-treatment-navi-mumbai' element={<Diabtic/>}/>
    <Route path='/circumcision-surgeon-navi-mumbai' element={<Circumsion/>}/>
    <Route path='/best-piles-doctor-kopar-khairane' element ={<Piles/>}/>
    <Route path='/best-doctor-for-hernia-surgery-navi-mumbai' element ={<Stitch/>}/>
    <Route path='/lump-removal-surgeon-navi-mumbai' element ={<BrestPump/>}/>
    <Route path='/best-surgeon-for-piles-navi-mumbai' element ={<LasurSur/>}/>
    <Route path='/hydrocele-treatment-doctor-vashi' element ={<Hydrocele/>}/>
    <Route path='/sinus-surgery-doctors-navi-mumbai' element ={<Pilon/>}/>
    <Route path='/best-piles-treatment-kopar-khairane' element ={<Advance/>}/>
  </Routes>
  </>
  );
}

export default App;
