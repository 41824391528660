import React from 'react'
import { Image} from 'antd';
import Footer from '../Components/Footer/Footer';
import { useEffect } from 'react';
import  saamananews from '../Components/Images/saamana-news.png'
import pressjournal from '../Components/Images/press-journal.png'
import bannerimage from '../Components/Images/banner-image.png'

function Media() {
  useEffect(()=>{
    document.title="Laser Surgeon For Piles In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul is a renowned Laser Surgeon for Piles in Navi Mumbai, offering advanced treatments with precision and expertise."
    )
    
  })
  return (
    <>

    <p className='hide-con'>
    Looking for a reliable laser surgeon for piles in Navi Mumbai? Look no further! Our clinic offers advanced laser treatment for piles, ensuring a pain-free and quick recovery. Our highly skilled laser surgeon for piles in Navi Mumbai uses state-of-the-art technology to provide effective and minimally invasive treatment. Say goodbye to discomfort and long recovery times with our expert care. Visit us today for a consultation and take the first step towards a healthier, happier life. Trust the best for your health needs and experience the difference with our top-rated laser treatment services.
    </p>
       <div
  className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
  style={{ backgroundImage: `url(${bannerimage})` }}
>
  <div className="container">
    <div className="dlab-bnr-inr-entry">
      <h1 className="text-white">Laser Surgeon For Piles In Navi Mumbai </h1>
      {/* Breadcrumb row */}
      <div className="breadcrumb-row">
        <ul className="list-inline">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Media</li>
        </ul>
      </div>
      {/* Breadcrumb row END */}
    </div>
  </div>
</div>

<section className='faq-box'>
    <div className="container">
    <div className="container page-top">
      
  <div className="row">
    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
      <a>
        <Image
          src={saamananews}
          className="zoom img-fluid "
          alt="saamana news"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 thumb">
      <a >
        <Image
          src={pressjournal}
          className="zoom img-fluid"
          alt="press journal"
        />
      </a>
    </div>
  </div>
  <div style={{display:'none'}}>
  <h4 className='mt-5'>Laser Surgeon for Piles in Navi Mumbai: Advanced and Effective Treatment</h4>
      <p>Are you searching for a reliable laser surgeon for piles in Navi Mumbai? Look no further! Piles, also known as hemorrhoids, can cause discomfort and pain, but advanced laser surgery offers a minimally invasive solution with faster recovery times. At our clinic in Navi Mumbai, we provide expert care, using state-of-the-art laser technology to ensure a smooth and effective treatment process for piles. </p>
      <h4>Why Choose Laser Surgery for Piles? </h4>
      <p>Laser surgery is becoming the preferred option for treating piles due to its numerous advantages over traditional methods. Here’s why opting for a laser surgeon for piles in Navi Mumbai is the right choice: </p>
      <p><b>Minimally Invasive:  </b>Laser treatment involves no cuts or stitches, making it less painful compared to conventional surgery. </p>
      <p><b>Quick Recovery:   </b> Most patients can return to their normal activities within a few days, thanks to the swift healing process.  </p>
      <p><b>Reduced Risk of Complications:   </b>The laser’s precision reduces the risk of infection, bleeding, or recurrence of piles.  </p>
      <p><b>Painless Procedure:   </b>Laser treatment is known for being virtually painless, both during and after the procedure.  </p>
      <b> Expert Laser Surgeons in Navi Mumbai    </b>
      <p>
      Our team of experienced laser surgeons in Navi Mumbai is committed to providing the highest quality care. With a patient-centric approach, we ensure that each individual receives a personalized treatment plan tailored to their specific needs. We understand the sensitive nature of piles and work with utmost professionalism and compassion to make the process as comfortable as possible. 
      </p>
      <b> Book Your Consultation Today   </b>
      <p>
      Don’t let piles affect your daily life any longer. Consult with the leading laser surgeon for piles in Navi Mumbai and get rid of piles painlessly and quickly. Contact us today to schedule an appointment and take the first step towards a healthier, pain-free life. 
      </p>
      <p>
      For the best results, trust the expertise of our skilled surgeons, and experience the benefits of advanced laser treatment in Navi Mumbai. 
      </p>
      </div>
</div>

    </div>
</section>

        <Footer/>

    </>
  )
}

export default Media
