import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import pilessurgery from '../Components/Images/piles-surgery.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Piles() {
  useEffect(()=>{
    document.title="Best Piles Doctor In kopar khairane| Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul is the best piles doctor in kopar khairane, offering expert treatment and care for hemorrhoid issues."
    )
    
  })
  return (
    <>
    <div className="page-wraper">
      <div
        className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
        style={{ backgroundImage: `url(${bannerimage})` }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Best Piles Doctor In kopar khairane</h1>
            {/* Breadcrumb row */}
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Services</li>
                <li>Piles Surgery</li>
              </ul>
            </div>
            {/* Breadcrumb row END */}
          </div>
        </div>
      </div>
      {/* inner page banner END */}
      {/* Main Slider */}
      <div
        className="section-full bg-white content-inner-2 "
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>Piles Surgery</h2>
              <img
                src={pilessurgery}
                alt="piles surgery in navi mumbai"
                className="img-thumbnail"
                width="100%"
              />
              <br />
              <br />
              <p>
              Piles do not cause any symptoms, and most people are unaware that they have them. If symptoms arise, they may include:
              </p>
              <ul className="padd-l">
                <li>Bleeding after bowel movements</li>
                <li>A mass within the anal canal or near the anus</li>
                <li>Itchy bottom.</li>
                <li>Sore and swollen anus.</li>
                <li>Slimy mucous discharge following a bowel movement.</li>
              </ul>
              <p>
              Piles typically resolve on their own, but dietary adjustments and modest therapies may be prescribed to alleviate symptoms in the interim. Severe piles may require more rigorous therapy, depending on their location
              </p>
              <p>
              For the best treatment of piles in Kopar Khairane, consulting the "Best Piles Doctor in Kopar Khairane" is recommended. Non-surgical therapies for piles located in the top two-thirds of the anal canal may include:
              </p>
              <ul className="padd-l">
                <li>Banding</li>
                <li>Sclerotherapy</li>
                <li>Electrotherapy</li>
              </ul>
              <p>
              However, piles in the lowest third, closest to the anus, will require surgery under anesthesia since the number of pain-sensing neurons in this area would make non-surgical treatments too uncomfortable.
              </p>
              <p>
              Although piles may typically be addressed with lifestyle changes, lotions, ointments, or non-surgical methods, approximately one in every ten people will require surgery. The primary surgical methods used to treat piles are hemorrhoidectomy, stapling, and hemorrhoidal artery ligation.
              </p>
              <h3>Haemorrhoidectomy</h3>
              <p>
              A hemorrhoidectomy is the surgical removal of internal or external haemorrhoids, typically under general anesthesia. This procedure is the most effective treatment for severe hemorrhoids.
              </p>
              <p>
              The most common surgery for internal hemorrhoids is a closed haemorrhoidectomy. In this procedure, hemorrhoids are removed using a sharp instrument or laser, and the incisions are sutured together. After the surgery doctors will recommend that patients take sitz baths, minimize constipation, and use modest pain relievers. The treatment is successful in virtually every case, although potential complications include
              </p>
              <ul className="padd-l">
                <li>Pain</li>
                <li>Urinary tract infection</li>
                <li>Urinary retention</li>
                <li>Fecal incontinence</li>
                <li>Fecal impaction</li>
                <li>Anal stricture</li>
              </ul>
              <p>
              If you're seeking the best piles doctor in Kopar Khairane, it's important to know the differences in treatment options for hemorrhoidectomy. Closed hemorrhoidectomy, though known for causing significant postoperative discomfort, offers the best long-term results and has the lowest recurrence rate. Researchers are actively working on strategies to minimize postoperative pain for this procedure.
              </p>
              <p>
              Alternatively, an open haemorrhoidectomy, also performed under general anesthesia, involves excising the hemorrhoids and leaving the wound open. This approach is often chosen for large or awkwardly located hemorrhoids where closing the incision might be challenging or increase the risk of infection. The risks associated with open hemorrhoidectomy are comparable to those of closed surgery. For expert advice and treatment, consult the best piles doctor in Kopar Khairane.
              </p>
            </div>
          </div>
        </div>
        {/* /card */}
      </div>
    </div>
    <br />
    <br />
  
    <Footer/>
  </>
  
  )
}

export default Piles
