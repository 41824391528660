import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import diabeticfoot from '../Components/Images/diabetic-foot.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Diabtic() {
  useEffect(()=>{
    document.title="Diabetic Foot Treatment In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul offers expert diabetic foot treatment in Navi Mumbai, ensuring comprehensive care and advanced solutions for foot complications."
    )
    
  })
  return (
  
      <>
  <div className="page-wraper">

   
    {/* inner page banner */}
    <div
      className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
      style={{ backgroundImage: `url(${bannerimage})` }}
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">Diabetic Foot Treatment In Navi Mumbai </h1>
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Services</li>
              <li>Diabetic Foot</li>
            </ul>
          </div>
          {/* Breadcrumb row END */}
        </div>
      </div>
    </div>
    {/* inner page banner END */}
    {/* Main Slider */}
    <div
      className="section-full bg-white content-inner-2 "
      style={{ backgroundColor: "black" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2>Diabetic Foot</h2>
            <img
              src={diabeticfoot}
              alt="diabetic foot treatment in navi mumbai"
              className="img-thumbnail"
              width="100%"
            />
            <br />
            <br />
            <p>
            Diabetes Foot represents several diseases where prolonged high blood glucose levels can damage nerves, kidneys, eyes, and blood vessels. Diabetes can also reduce the body's ability to fight infections. When diabetes is not well controlled, organ damage and immune system impairment are likely, leading to serious foot problems. 
            </p>
            <p>
            Nerve damage may cause numbness or loss of sensation in the feet. This can impair normal sweat secretion and oil production that lubricates the skin. These factors can create abnormal pressure on the skin, bones, and joints of the foot while walking, resulting in skin breakdown and potentially leading to ulcers. 
            </p>
            <p>
            Furthermore, damage to blood vessels and immune system impairment from diabetes complicate the healing of these wounds. Bacterial infections can develop in the skin, connective tissues, muscles, and bones, leading to gangrene. Due to poor blood flow, antibiotics struggle to reach the infection site effectively. Managing diabetes, improving blood flow to the legs, and employing surgical and regular dressing interventions are often necessary. If the infection spreads to the bloodstream, it can become life-threatening. 
            </p>
            <p>
            People with diabetes must proactively prevent foot problems, recognize issues early, and seek appropriate treatment. Although treatments for diabetic foot problems have advanced, good blood sugar control remains the best preventative measure against diabetic complications. 
            </p>
            <p>
            Patients should learn how to examine their feet and recognize early signs and symptoms of diabetic foot problems. They must also understand how to manage routine foot care at home, know when to call a doctor, and recognize when a problem is serious enough to require emergency treatment. 
            </p>
            {/* <ul className="padd-l">
              <li>
              A diabetic may lose feeling in his or her feet due to nerve degeneration. Normal perspiration secretion and oil production, which moisten the skin of the foot, are impeded. These elements, when combined, can cause abnormal strain on the foot's skin, bones, and joints during walking, as well as skin disintegration. Sores may develop. Diabetes causes damage to blood vessels and impairs the immune system, making wound healing harder. Bacterial infections of the skin, connective tissues, muscles, and bones may then ensue. These infections can lead to gangrene. Because of the limited blood flow, antibiotics cannot easily reach the site of infection. Often, the only treatment is to amputate the foot or leg. If the infection spreads to the bloodstream, the outcome could be fatal.
              </li>
              <li>
              People with diabetes must understand how to avoid foot problems from occurring, how to spot problems early, and how to seek appropriate treatment when they do occur. Although therapy for diabetic foot problems has improved, prevention, including proper blood sugar control, remains the most effective strategy to avoid diabetes complications. Diabetics should learn how to check their own feet and identify the early signs and symptoms of diabetic foot issues. For those seeking Diabetic Foot Treatment in Navi Mumbai, it is crucial to choose a healthcare provider 
              </li>
              <li>
              People with diabetes must understand how to avoid foot problems from occurring, how to spot problems early, and how to seek appropriate treatment when they do occur. Although therapy for diabetic foot problems has improved, prevention, including proper blood sugar control, remains the most effective strategy to avoid diabetes complications. Diabetics should learn how to check their own feet and identify the early signs and symptoms of diabetic foot issues. For those seeking Diabetic Foot Treatment in Navi Mumbai, it is crucial to choose a healthcare provider 
              that emphasizes prevention and early detection. With the right Diabetic Foot Treatment in Navi Mumbai, patients can significantly reduce the risk of severe complications and maintain better overall foot health.
They should also understand what is reasonable for ordinary at-home foot care, when to call the doctor, and when a problem has become serious enough to require emergency care.

              </li>
              <ul className="padd-l">
                <li>
                  People with diabetes should learn how to examine their own
                  feet and how to recognize the early signs and symptoms of
                  diabetic foot problems.
                </li>
                <li>
                  They should also learn what is reasonable to manage routine at
                  home foot care, how to recognize when to call the doctor, and
                  how to recognize when a problem has become serious enough to
                  seek emergency treatment.
                </li>
              </ul>
            </ul> */}
            <h3>Medical Treatment for Diabetic Foot</h3>
            <ul className="padd-l">
              <li>
                <b>Antibiotics : </b>If a wound or ulcer is infected or at high risk of infection, antibiotics will be prescribed. It is crucial for patients to complete the entire course of antibiotics as prescribed. Patients should expect to see improvement within two to three days. For severe infections, hospitalization and IV antibiotics may be necessary, while less serious infections may be treated with oral antibiotics. 
              </li>
              <li>
                <b>Referral to Wound Care Centre: </b>Many larger community hospitals have specialized wound care centers that focus on treating diabetic lower extremity wounds and ulcers. These multidisciplinary centers involve doctors, nurses, and therapists collaborating on a treatment plan that may include surgical debridement, blood circulation improvement, special dressings, and antibiotics. In extreme cases of gangrene, amputations may be required. 
              </li>
              <li>
                <b>Referral to Podiatrist or Orthopedic Surgeon: </b>Patients with bone-related issues, toenail problems, corns, calluses, or other foot conditions may be referred to these specialists. They can create shoe inserts, prescribe proper footwear, remove calluses, and provide surgical solutions for bone problems. They are also valuable resources for routine foot care. 
              </li>
              <li>
                <b>Home Health Care:  </b>A physician may prescribe a home health nurse or aide to assist with wound care, dressings, blood sugar monitoring, and proper medication administration during the healing process. 
              </li>
            </ul>
            <p>
            For effective Diabetic Foot Treatment in Navi Mumbai, it is essential for patients to be proactive about their foot care. By collaborating with healthcare professionals and following a comprehensive treatment plan, individuals can manage their diabetic foot health more effectively. Seeking Diabetic Foot Treatment in Navi Mumbai ensures access to specialized care tailored to their needs. Those requiring more guidance should consider consulting professionals in Diabetic Foot Treatment in Navi Mumbai to prevent complications and maintain optimal foot health. 
            </p>
          </div>
        </div>
      </div>
      {/* /card */}
    </div>
  </div>
  <br />
  <br />
 
<Footer/>

    </>
  )
}

export default Diabtic
