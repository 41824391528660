import React from 'react'
import "./surgen.css"
import whychooseus2 from '../Images/why-choose-us2.jpg'
function BestSurgen() {
  return (
    <>
      <div
  className="section-full bg-white content-inner-2"
  style={{
    backgroundImage:
      `url(${whychooseus2})`,
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundAttachment: "fixed"
  }}
>
  <div className="container">
    <br />
    <br />
    <br />
    <br />
    <div className="row">
      <div className="col-md-8 why-padd">
        <div className="section-head text-black">
          <h2 className="text-primary m-b10" style={{ marginTop: 30 }}>
            Best Hernia Surgeon in Navi Mumbai - Dr Abhijit Bagul
          </h2>
          {/* <h6 class="m-b10">Bariatric and Laparoscopic Surgery</h6> */}
          <b>
            {/*<p>We are Hernia Specialist Performing Hign Volumes of Hernia Surgery at our Speciality Clinic dedicated for Hernia care. Hernia Surgery is our passion Hernia Repair is on PRIORITY We perform hernia surgeries every day with highest level of care Every case is handled in well-planned manner to deliver – Holistic treatment with Improved Quality of Life Attention to all details given at the post-operative care to allow you to return to full-level of activities</p>*/}
            <p>
              
In Navi Mumbai, Hernia Specialist Surgeon Dr. Abhijit Bagul has garnered a reputation for his extensive expertise in hernia surgeries. Skilled in both laparoscopic, or minimally invasive, and open hernia repair procedures, he has successfully completed numerous surgeries with utmost precision over his more than two decades of experience. Each case is managed with meticulous planning to provide holistic care and enhance the quality of life for his patients. With thorough post-operative care instructions, Dr. Bagul ensures that patients can resume their regular activities promptly and safely.

            </p>
            <div className="row">
              <div className="col-md-6 why-choose">
                <p>
                  <span className="why-choose-us"> H </span> Highly rated in
                  patient satisfaction
                </p>
                <p>
                  <span className="why-choose-us"> E </span> Experts in Hernia
                  Care
                </p>
                <p>
                  <span className="why-choose-us"> R </span> Respected in
                  medical community
                </p>
              </div>
              <div className="col-md-6 why-choose">
                <p>
                  <span className="why-choose-us"> N </span> No more pain – we
                  assure
                </p>
                <p>
                  <span className="why-choose-us">&nbsp; I </span> Information
                  Class Free on Hernia
                </p>
                <p>
                  <span className="why-choose-us"> A </span> Active in hernia
                  research
                </p>
              </div>
            </div>
          </b>
          {/* <div class="text-center">
              <a href="about.php" class="site-button outline pull-left">Read More</a> 
          </div>
 */}
        </div>
      </div>
      <div className="col-md-4"></div>
    </div>
  </div>
</div>

    </>
  )
}

export default BestSurgen
