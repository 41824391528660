import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import gallbladderremovalsurgery from '../Components/Images/gall-bladder-removal-surgery.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function GallBlader() {
  useEffect(()=>{
    document.title="Gall Bladder Stone Treatment In Vashi | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Get expert Gall Bladder Stone Treatment in Vashi from Dr. Abhijit Bagul, offering advanced care and effective solutions."
    )
    
  })
  return (
    <>
    <div className="page-wraper">
      {/* inner page banner */}
      <div
        className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
        style={{ backgroundImage: `url(${bannerimage})` }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">
            Gall Bladder Stone Treatment In Vashi
            </h1>
            {/* Breadcrumb row */}
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Services</li>
                <li>Gall Bladder Removal Surgery</li>
              </ul>
            </div>
            {/* Breadcrumb row END */}
          </div>
        </div>
      </div>
      {/* inner page banner END */}
      {/* Main Slider */}
      <div
        className="section-full bg-white content-inner-2 "
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>Gall Bladder Removal Surgery</h2>
              <img
                src={gallbladderremovalsurgery}
                alt="gall bladder removal surgery (cholecystectomy) in navi mumbai"
                className="img-thumbnail"
                width="100%"
              />
              <br />
              <br />
              <p>
              Surgery performed to remove the gallbladder due to various health conditions is known as gallbladder removal surgery. The gallbladder is an organ located next to the liver, responsible for storing bile a liquid synthesized by the liver that aids in digesting food after it enters the small intestine. Bile is secreted through a tube-like structure called the cystic duct, which joins with the hepatic duct from the liver to form the common bile duct. This, along with the pancreatic duct, opens into the small intestine to secrete digestive fluids, facilitating further digestion after food leaves the stomach.               </p>
              <p>
              Gallbladder removal surgery, or cholecystectomy, is performed when the gallbladder is functioning abnormally due to infection or the formation of gallstones, leading to symptoms such as recurring abdominal pain, fever, and vomiting. In extreme cases, untreated gallstones can lead to gangrene or sepsis. 
              </p>



              <h3>Methods of Gallbladder Removal Surgery </h3>
              <p>
Gallbladder removal can be done using the traditional open method or the minimally invasive laparoscopic method, each with its advantages and disadvantages. 
              </p>
              <ul className="padd-l">
                <li>
                  <b>Open Method : </b>
                  This invasive procedure starts with a single surgical incision 4 to 8 inches long beneath the rib cage on the right side of the abdomen or a large vertical incision in the center of the abdomen. After cutting through the skin and underlying tissues, the abdominal muscles are separated to visualize the liver and gallbladder. The gallbladder is isolated, and its attachments are carefully cut. The cystic duct and endocystic artery are ligated, allowing the gallbladder to be removed. Although this method has a higher risk of bleeding and infection due to the larger incision, it is necessary in cases of cancer, severe inflammation, or portal hypertension. The procedure takes about 1 to 2 hours. 
                  {/* <br />
                  When the gallbladder malfunctions due to a condition, Gall Bladder Stone Treatment In Vashi may involve its removal either laparoscopically or openly. The procedure typically lasts about 45 minutes to an hour. */}
                </li>
                <li>
                  {" "}
                  <b>Laparoscopic Method: </b>
                  This newer method is minimally invasive and involves smaller incisions in the abdominal wall. After making the incisions, a laparoscope with a camera is inserted through one incision, while surgical instruments are introduced through others. The gallbladder is then extracted, and the incisions are closed. This method reduces the risk of infection and speeds up recovery, typically lasting about 30 minutes to an hour. However, if complications arise, laparoscopic surgery may need to be converted to an open procedure. 
                </li>
                  <br />
              </ul>
                  <p>
                    
                  After the gallbladder is removed, the surgeon will close any surgical incisions. The little incisions heal quickly and do not leave permanent scars. The chance of causing inadvertent harm to a blood artery or other organ while making the incision is reduced. Because there is minimal 
                  </p>
                  {/* <br /> */}
                  <p>
                  exposure to the abdominal organs, the danger of infection is considerably reduced. However, laparoscopic surgery does not always yield positive results.In such circumstances, the surgeon may have to use the open approach to remove the gallbladder. The process lasts around 45 minutes.
                  </p>
                  {/* <br /> */}
                  {/* Both approaches differ slightly in how they are executed; nonetheless, the end outcome is the same in both procedures. If malignancy is suspected, the gallbladder might be sent to a pathologist. Following surgery, there is a 4-6 week recovery period during which the patient must take the best possible care of their health. The recovery period after laparoscopic surgery is shorter. */}
            </div>
          </div>
        </div>
        {/* /card */}
      </div>
    </div>
    <br />
    <br />
  
   <Footer/>
  </>
  )
}

export default GallBlader
