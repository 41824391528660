import React from 'react'
import logo from '../Images/dr-abhijit-logo.png'
import { NavLink } from 'react-router-dom'
function Footer() {
  return (
    <>
      <footer className="site-footer  footer-white">
  <div className="footer-top">
    <div className="container wow fadeIn" data-wow-delay="0.5s">
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <div className="widget">
            <h6 className="m-b30">
             <NavLink to='/'> <img
                // src="https://www.drabhijitbagul.com/img/dr-abhijit-logo-1.png"
                src={logo}
                alt="Dr Abhijit Bagul "
                style={{ width: "76%", marginBottom: "-16px" }}
              /></NavLink>
            </h6>
            <p className="text-capitalize m-b20">
              MS, FIAGES, FMAS, FCPS (Surgery), Hernia Specialist &amp;
              Laparoscopic Surgeon of Navi Mumbai’s Speciality Clinic dedicated
              for Hernia care.
            </p>
            <div className="subscribe-form m-b20">
              <form
                className="dzSubscribe"
                action="https://beautyzone.dexignzone.com/xhtml/script/mailchamp.php"
                method="post"
              >
                <div className="dzSubscribeMsg" />
                <div className="input-group">
                  <span className="input-group-btn">
                    <a href="/best-fistula-surgery-doctor-near-navi-mumbai" className="site-button radius-xl">
                      Read More
                    </a>
                  </span>
                </div>
              </form>
            </div>
            {/*<ul class="list-inline m-a0">*/}
            {/*    <li><a href="#" class="site-button" style="margin-top: -112px; margin-right: 147px;"> <i class="fa fa-arrow-up"></i></a>*/}
            {/*    </li>*/}
            {/* </ul>*/}
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-5">
          <div className="widget widget_services border-0">
            <h6 className="m-b20">Useful Link</h6>
            <ul>
              <li>
                <a href="/">Home </a>
              </li>
              <li>
                <a href="/best-fistula-surgery-doctor-near-navi-mumbai">About Us </a>
              </li>
              <li>
                <a href="/best-proctologist-navi-mumbai">Gallery</a>
              </li>
              <li>
                <a href="/piles-specialist-doctor-navi-mumbai">Blog</a>
              </li>
              <li>
                <a href="/fissure-surgeon-near-kopar-khairane">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-7">
          <div className="widget widget_services border-0">
            <h6 className="m-b20">Services</h6>
            <ul>
              <li>
                <a href="/hernia-treatment-navi-mumbai" className="dez-page">
                  Hernia Surgery
                </a>
              </li>
              <li>
                <a href="/appendicitis-surgeon-navi-mumbai" className="dez-page">
                  Appendix Surgery{" "}
                </a>
              </li>
              <li>
                <a href="/gall-bladder-stone-treatment-vashi" className="dez-page">
                  Gall Bladder Removal Surgery
                </a>
              </li>
              <li>
                <a href="/diabetic-foot-treatment-navi-mumbai" className="dez-page">
                  Diabetic Foot
                </a>
              </li>
              <li>
                <a href="/circumcision-surgeon-navi-mumbai" className="dez-page">
                  Circumcision
                </a>
              </li>
              <li>
                <a href="/best-piles-doctor-kopar-khairane" className="dez-page">
                  Piles Surgery
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5">
          <div className="widget widget_getintuch">
            <h6 className="m-b30">Contact us</h6>
            <ul>
              <li>
                <i className="fa fa-map-marker" />
                <strong>Dr Bagul's Proctology Clinic</strong> Plot 12-13, Sector
                20, Kopar Khairane, Navi Mumbai, Maharashtra 400709
              </li>
              <li>
                <i className="fa fa-phone" />
                <strong>phone</strong>+91-9820144713
              </li>
              {/*<li><i class="fa fa-envelope"></i><strong>email</strong>info@drbagul.in</li>*/}
            </ul>
          </div>
          <ul className="list-inline " style={{ marginRight: "-100px" }}>
            <li>
              <a href="#" className="site-button topup">
                {" "}
                <i className="fa fa-arrow-up" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  {/* footer bottom part */}
  <div className="footer-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 text-center text-md-left">
          <center>
            {" "}
            <span>
              Copyright © 2018 <a href="index.php">Dr Abhijit Bagul.</a> All
              Rights Reserved.
            </span>
          </center>
        </div>
      </div>
      {/*whatsapp and call*/}
      {/*             <div class="row">*/}
      {/*             <div class="col-lg-12 col-md-12 col-sm-12 navbar-fixed-bottom d-lg-none d-xl-none d-md-none d-sm-none row w-100"*/}
      {/*    style="text-align: center;position: fixed;right: 0;bottom: 0;left: 0;padding: 4px;;text-align: center;z-index:29">*/}
      {/*    <div class="col-lg-6 col-md-6 col-sm-6 >*/}
      {/*    <a href="tel:91 9820144713" style="color:white;">*/}
      {/*        <div class="d-lg-none d-xl-none d-md-none d-sm-none col-xs-6"*/}
      {/*            style="background-color:#2192C5;margin-top: 6px;padding:8px  ">*/}
      {/*            <img src="img/call.png" height="32" width="32" alt="Call">Contact Now*/}
      {/*        </div>*/}
      {/*    </a>  */}
      {/*    </div>*/}
      {/*    <div class="col-lg-6 col-md-6 col-sm-6 >*/}
      {/*    <a href="https://api.whatsapp.com/send?phone=+91 9820144713  &amp;text=Hi,%20I%20want%20to%20Book%20an%20Appointment%20For%20Lotus%20Dental%20Care%20Clinic" style="color:white;;">*/}
      {/*        <div class="d-lg-none d-xl-none d-md-none d-sm-none col-xs-6"*/}
      {/*            style="background-color:#65BC54;margin-top: 6px;padding:8px;">*/}
      {/*            <img src="img/whatsapp.png" height="32" width="32" alt="Call"> WhatsApp*/}
      {/*        </div>*/}
      {/*    </a>*/}
      {/*     </div>*/}
      {/*</div>*/}
      {/*             </div>*/}
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer
