import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import logo from '../Images/dr-abhijit-logo.png'


function Navbarr() {
  return (
    <header>
      <div className="top-bar bg-primary text-white py-2">
        <Container>
          <div className="d-flex justify-content-between">
            <div className="dlab-topbar-left">
              <ul className="list-unstyled mb-0">
                <li>
                  <i className="fa fa-phone mr-2" /> +91-9820144713
                </li>
              </ul>
            </div>
            <div className="dlab-topbar-right topbar-social">
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="https://www.facebook.com/DrAbhijitBagul/" className="site-button-link facebook hover d-none d-sm-block text-white">
                    <i> <FaFacebookF />
                    </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      <Navbar bg="light" expand="lg" sticky="top">
        <Container className=' chont'>
          <Navbar.Brand href="/">
            <img
              // src="https://www.drabhijitbagul.com/img/dr-abhijit-logo-1.png"
              src={logo}
              alt="Dr Abhijit Bagul logo"
              height="40"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNavDropdown" />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav className="ml-auto">
              <Nav.Link href="/"> Home</Nav.Link>
              <Nav.Link href="/best-fistula-surgery-doctor-near-navi-mumbai">About Us</Nav.Link>
              <NavDropdown title="Services" id="services-dropdown">
                <NavDropdown.Item href="/hernia-treatment-navi-mumbai">Hernia Surgery</NavDropdown.Item>
                <NavDropdown.Item href="/appendicitis-surgeon-navi-mumbai">Appendix Surgery / Appendectomy</NavDropdown.Item>
                <NavDropdown.Item href="/gall-bladder-stone-treatment-vashi">Gall Bladder Removal Surgery (Cholecystectomy)</NavDropdown.Item>
                <NavDropdown.Item href="/diabetic-foot-treatment-navi-mumbai">Diabetic Foot</NavDropdown.Item>
                <NavDropdown.Item href="/circumcision-surgeon-navi-mumbai">Circumcision / Male Genital Mutilation (MGM)</NavDropdown.Item>
                <NavDropdown.Item href="/best-piles-doctor-kopar-khairane">Piles Surgery</NavDropdown.Item>
                <NavDropdown.Item href="/best-doctor-for-hernia-surgery-navi-mumbai">Stitch-less Hernia</NavDropdown.Item>
                <NavDropdown.Item href="/lump-removal-surgeon-navi-mumbai">Breast Lump Removal (Lumpectomy)</NavDropdown.Item>
                <NavDropdown.Item href="/best-surgeon-for-piles-navi-mumbai">Laser surgery for Piles, Fissure and Fistula</NavDropdown.Item>
                <NavDropdown.Item href="/hydrocele-treatment-doctor-vashi">Hydrocele Treatment</NavDropdown.Item>
                <NavDropdown.Item href="/sinus-surgery-doctors-navi-mumbai">Pilonidal Sinus</NavDropdown.Item>
                <NavDropdown.Item href="/best-piles-treatment-kopar-khairane">Advanced Laser Proctology</NavDropdown.Item>
                <NavDropdown.Item href="/advanced-laparoscopic-surgeon-navi-mumbai">Advanced GI Laparoscopic Surgery</NavDropdown.Item>

                
              </NavDropdown>
              <NavDropdown title="Gallery" id="gallery-dropdown">
                <NavDropdown.Item href="/best-proctologist-navi-mumbai">Surgical Archive</NavDropdown.Item>
                <NavDropdown.Item href="/laser-surgeon-for-piles-navi-mumbai">Media</NavDropdown.Item>
                <NavDropdown.Item href="/SurgicalArchieve">Videos</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/best-gastroenterologist-vashi">Testimonials</Nav.Link>
              <Nav.Link href="/best-hernia-surgeon-navi-mumbai">FAQ's</Nav.Link>
              <Nav.Link href="/piles-specialist-doctor-navi-mumbai" 
              // target="_blank"
              >Blog</Nav.Link>
              <Nav.Link href="/fissure-surgeon-near-kopar-khairane">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Navbarr;
