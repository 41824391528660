import React from 'react'

function Counter() {
  return (
    <>
    <div className="section-full  bg-white">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className="counter">
            <span className="counter-value">22</span>
            <p className="plus">+</p>
            <h3>Experience</h3>
            <div className="counter-icon">
              <i className="fa fa-user-md" />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="counter">
            <span className="counter-value">5000</span>
            <p className="plus2">+</p>
            <h3>Surgery Performed</h3>
            <div className="counter-icon">
              <i className="fa fa-plus-square" />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="counter">
            <span className="counter-value">8000</span>
            <p className="plus2">+</p>
            <h3>Happy Patients</h3>
            <div className="counter-icon">
              <i className="fa fa-users" />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="counter">
            <span className="counter-value">4</span>
            <p className="plus3">+</p>
            <h3>Google Rating</h3>
            <div className="counter-icon">
              <i className="fa fa-star" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </div>
    </>
    
  
  )
}

export default Counter
