import React from 'react'
import "./special.css"
import { Link } from 'react-router-dom'
import sone from '../Images/sone.jpg'
import stwo from '../Images/stwo.jpg'
import sthree from '../Images/sthree.jpg'
import sfour from '../Images/sfour.jpg'
import sfive from '../Images/sfive.jpg'
import ssix from '../Images/ssix.jpg'
import sseven from '../Images/sseven.jpg'
import seight from '../Images/seight.jpg'
function Speciality() {
  return (
    <>
      <div
  className="section-full content-inner-2"
  style={{
    background: "lightgray",
    backgroundPosition: "bottom",
    backgroundSize: "cover"
  }}
>
  <div className="container">
    <div className="section-head text-black text-center">
      <h2 className="text-primary m-b10">Our Speciality</h2>
      <h6 className="m-b10">What we offers</h6>
      {/* <div class="dlab-separator-outer m-b0">
                  <div class="dlab-separator text-primary style-icon"><i class="flaticon-spa text-primary"></i></div>
              </div> */}
      {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the.</p> */}
    </div>
    {/*<div class="img-carousel owl-carousel owl-theme owl-none owl-dots-primary-big owl-btn-center-lr owl-loade m-b30">*/}
    <div className="row">
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={sone}
              alt="hernia surgery & treatment in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/hernia-treatment-navi-mumbai" className="text-primary">
                Hernia Surgery
              </Link>
            </h6>
            <p>
              A hernia is a bulge or swelling that happens as an inner body part
              pokes through a weak area...
            </p>
          </div>
        </div>
      </div>
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={stwo}
              alt="appendix removal surgery in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/appendicitis-surgeon-navi-mumbai" className="text-primary">
                Appendix Surgery
              </Link>
            </h6>
            <p>
              An appendectomy is a surgical procedure to remove your appendix,
              the small tube connected...
            </p>
          </div>
        </div>
      </div>
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={sthree}
              alt="gall bladder removal surgery (cholecystectomy) in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link
                to="/gall-bladder-stone-treatment-vashi"
                className="text-primary"
              >
                Gall Bladder Removal Surgery
              </Link>
            </h6>
            <p>
              Surgery done to excise the gall bladder because of some health...
            </p>
          </div>
        </div>
      </div>
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={sfour}
              alt="diabetic foot treatment in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/diabetic-foot-treatment-navi-mumbai" className="text-primary">
                Diabetic Foot
              </Link>
            </h6>
            <p>
              Diabetes Foot represents several diseases in which high blood...
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div className="row">
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={sfive}
              alt="circumcision surgery in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/circumcision-surgeon-navi-mumbai" className="text-primary">
                Circumcision
              </Link>
            </h6>
            <p>
              Circumcision is the surgical removal of the foreskin, which is the
              skin covering the tip of the penis. It’s common in the United
              States and parts of Africa...{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={ssix}
              alt="piles surgery in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/best-surgeon-for-piles-navi-mumbai" className="text-primary">
                Laser Surgery for Piles, Fissure and Fistula
              </Link>
            </h6>
            <p>Piles are not associated with any symptoms and people... </p>
          </div>
        </div>
      </div>
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={sseven}
              alt="laser surgery for piles, fissure and fistula in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/best-doctor-for-hernia-surgery-navi-mumbai" className="text-primary">
                Laser Proctology
              </Link>
            </h6>
            <p>
              Laser proctology is the surgical treatment of diseases of the
              colon, rectum, and anus using a laser...{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="item item1 col-md-3">
        <div className="service-box text-center">
          <div className="service-images m-b15">
            <img
              width={300}
              height={300}
              src={seight}
              alt="breast lump removal (lumpectomy) in navi mumbai"
            />
            <span>+</span>
          </div>
          <div className="service-content">
            <h6 className="text-uppercase">
              <Link to="/lump-removal-surgeon-navi-mumbai" className="text-primary">
                Breast Lump
              </Link>
            </h6>
            <p>
              Breast lump removal is the surgical removal of a cancerous lump
              inside the breast. It’s also known as a lumpectomy....{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
    {/*	<div class="item item1" >*/}
    {/*		<div class="service-box text-center">*/}
    {/*			<div class="service-images m-b15">*/}
    {/*				<img width="300" height="300" src="img/index-page/services/laser-surgery-for-piles.jpg" alt=""/>*/}
    {/*				<i class="fa fa-plus"></i>*/}
    {/*			</div>*/}
    {/*			<div class="service-content">*/}
    {/*				<h6 class="text-uppercase"><a href="laser-surgery-for-piles.php" class="text-primary">Laser surgery for Piles</a></h6>*/}
    {/*				<p>Hemorrhoidectomy is surgery to remove hemorrhoids done under anesthesia (general or spinal)... </p>*/}
    {/*			</div>*/}
    {/*		</div>*/}
    {/*	</div>*/}
    {/*</div>*/}
    {/* <div class="text-center">
              <a href="#" class="site-button outline">See all Services</a> 
          </div> */}
  </div>
</div>

    </>
  )
}

export default Speciality
