import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import pilonidalsinus from '../Components/Images/pilonidal-sinus.png' 
import bannerimage from '../Components/Images/banner-image.png'
function Pilon() {
  useEffect(()=>{
    document.title="Sinus Surgery Doctors In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Expert sinus surgery doctors in Navi Mumbai, led by Dr. Abhijit Bagul, offering specialized care and treatment for sinus conditions."
    )
    
  })
  return (
    
    <>
    <div className="page-wraper">
      <div
        className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
        style={{ backgroundImage: `url(${bannerimage})` }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Sinus Surgery Doctors In Navi Mumbai </h1>
            {/* Breadcrumb row */}
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Services</li>
                <li>Pilonidal Sinus</li>
              </ul>
            </div>
            {/* Breadcrumb row END */}
          </div>
        </div>
      </div>
      {/* inner page banner END */}
      {/* Main Slider */}
      <div
        className="section-full bg-white content-inner-2 "
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>Pilonidal Sinus</h2>
              <img
                src={pilonidalsinus}
                alt="pilonidal sinus treatment in navi mumbai"
                className="img-thumbnail"
                width="100%"
              />
              <br />
              <br />
              <p> Pilonidal means a 'nest of hairs.' </p>
              <p>
              A sinus tract is a narrow tunnel (a small abnormal channel) in your body. A sinus tract typically goes between a focus of infection in deeper tissues to your skin surface. This means that the tract may discharge pus from time to time onto your skin.
             </p>
              <p>
              A pilonidal sinus is a sinus tract that commonly contains hairs. It occurs under your skin between your buttocks (the natal cleft) a short distance above your back passage (anus). The sinus tract goes in a vertical direction between your buttocks. Rarely, a pilonidal sinus occurs in other sites of your body. 
              </p>
              <h3>What is the treatment for pilonidal sinus?</h3>
              <h4>If you have no symptoms</h4>
              <p>
              If you have no symptoms, then you will normally be advised to clear the affected area of hairs (by shaving, etc.) and to keep the area clean with good personal hygiene. 
              </p>
              <h4>If you have rapid-onset (acute) symptoms</h4>
              <p>
              If you have an infection, then you may be given some medicines called antibiotics. Painkillers (such as paracetamol and/or ibuprofen) may be very helpful to improve the pain. It may be that you need to have an emergency operation. This procedure punctures (incises) and drains the ball of pus with the surrounding skin infection (abscess). This is usually done in the hospital. 
              </p>
              <h4>If you have persistent (chronic) symptoms</h4>
              <p>
              In most cases, an operation will be advised. There are various operations that can be done to cure this problem. Your surgeon will be able to give the details and the pros and cons of each operation. The options include the following: 
              </p>
              <p>
                <b>Wide excision and healing by secondary intention: </b> 
                This operation involves cutting out (excision of) the sinus but also cutting out a wide margin of skin surrounding the sinus. The wound is not closed but is left open to heal through natural processes (healing by 'secondary intention'). This usually requires several weeks of regular dressing changes until it heals fully. The advantage of this method is that all inflamed tissue is removed, and the chance of the condition coming back (a recurrence) is low. 
              </p>
              <p>
                <b>Excision and primary closure: </b>This means taking out the section of skin that contains the sinus. This is done by cutting out an oval-shaped (ellipse) flap of skin on either side of the sinus, which removes the sinus. The two sides of the ellipse are then stitched together. The advantage of this method is that, if successful, the wound heals quite quickly. However, the risk of recurrence or developing a wound infection after the operation is higher than with the above procedure. This risk may be reduced by using a wound technique in which the line of stitches is moved away from between the buttocks. 
              </p>
              <p>
                <b>A plastic surgery technique:</b>  In some cases, where the sinus recurs or is extensive, plastic surgery may be advised to remove the sinus and refashion the nearby skin. 

              </p>
              <p>
              There are variations on the above procedures, depending on your circumstances, the size and extent of the sinus, and whether it is a first or recurrent problem. Your surgeon will be able to discuss with you in detail the most suitable type of operation. For personalized care and expertise, consulting with Sinus Surgery Doctors In Navi Mumbai can provide valuable insights and treatment options. 
              </p>
              <p>
                
              New techniques are being researched to try to improve recovery after having an operation, and specialists can guide you through these options. If you're facing issues with a pilonidal sinus, don't hesitate to reach out to Sinus Surgery Doctors In Navi Mumbai for help. They will ensure you receive the best care tailored to your situation. 
              </p>
            </div>
          </div>
        </div>
        {/* /card */}
      </div>
    </div>
    <br />
    <br />
   
   <Footer/>
  </>
  
    
  )
}

export default Pilon
