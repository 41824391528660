import React from 'react'

function SecAbout() {
  return (
    <>
      <div className="section-full bg-white content-inner-2 bagul-clinic">
  <div className="container">
    <div className="row justify-content-end">
      <div className="col-md-5 text-left">
        <div className="section-head text-black">
          <h2 className="m-b10 text-white" style={{ marginTop: 30 }}>
            Dr Bagul's Proctology Clinic
          </h2>
          <p className="text-white">
          Dr. Bagul's Proctology Clinic, renowned as Navi Mumbai's premier comprehensive advanced proctology treatment facility, is also recognized for its expertise as a leading Hernia Specialist surgeon in Navi Mumbai. We are dedicated to alleviating the local population's colon, rectum, anus, and hernia-related ailments.{" "}
          </p>
          <p className="text-white">
          Thanks to our cutting-edge methodology and contemporary technologies, our clinic guarantees the best possibilities for colorectal surgery and hernia treatment. Our goal is to end the suffering of many individuals with colorectal and hernia issues by offering a top-notch center of excellence in coloproctology and hernia surgery.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default SecAbout
