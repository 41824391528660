import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import circumcision from '../Components/Images/circumcision.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Circumsion() {
  useEffect(()=>{
    document.title="Circumcision Surgeon In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Circumcision Surgeon in Navi Mumbai, Dr. Abhijit Bagul, offers expert, safe, and advanced circumcision procedures for patients of all ages."
    )
    
  })
  return (
    <>
  <div className="page-wraper">

    <div
      className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
      style={{ backgroundImage: `url(${bannerimage})` }}
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">
          Circumcision Surgeon In Navi Mumbai 
          </h1>
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Services</li>
              <li>Circumcision</li>
            </ul>
          </div>
          {/* Breadcrumb row END */}
        </div>
      </div>
    </div>
    {/* inner page banner END */}
    {/* Main Slider */}
    <div
      className="section-full bg-white content-inner-2 "
      style={{ backgroundColor: "black" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2>Circumcision</h2>
            <img
              src={circumcision}
              alt="circumcision surgery in navi mumbai"
              className="img-thumbnail"
              width="100%"
            />
            <br />
            <br />
            <p>
            Circumcision is the surgical removal of the foreskin, which is the skin covering the tip of the penis. It’s common in the United States and parts of Africa and the Middle East but less common in Europe and some countries.             </p>
            <p>
            The procedure is typically done on a newborn for personal or religious reasons. Circumcision in older children and adults may also be done for the same reasons. Additionally, older children or adults may need circumcision to treat several conditions, including:             </p>
            <ul className="padd-l">
              <li>Balanitis (swelling of the foreskin) </li>
              <li>
              Balanoposthitis (inflammation of the tip and foreskin of the penis) 
              </li>
              <li>
              Paraphimosis (inability to return a retracted foreskin to its original position) 
              </li>
              <li>Phimosis (inability to retract the foreskin)  </li>
            </ul>
            <p>
            In healthy newborns, there is no medical need for circumcision. However, families may choose to have their sons circumcised for a number of reasons. One of the most common reasons is religious tradition. The religious laws of both Judaism and Islam require that newborn boys be circumcised. Other reasons to circumcise include:             
            </p>
            {/* <p>
            One of the most prevalent explanations is religious tradition. Both Judaism and Islam have religious precepts requiring circumcision for newborn boys. Other reasons for circumcision include
            </p> */}
            <ul className="padd-l">
              <li>Personal choice </li>
              <li>Aesthetic preference </li>
              <li>Resulting lowered risk of some conditions </li>
              <li>Desire of some fathers to have their sons look like them </li>
            </ul>
            <h3>How a circumcision is performed </h3>
            <p>
            Circumcisions are often done by skilled surgeons. Circumcisions that are performed for religious reasons are sometimes done by others trained in the procedure. 
            </p>
            <p>
            During the newborn circumcision, your son will lay on his back with his arms and legs secured. An anesthetic is given via injection or cream to numb the penis. 
            </p>
            <p>
            There are several techniques for performing circumcision. The selection of technique is determined by prevailing trends, as well as the surgeon's preferences and expertise. The three primary techniques for circumcision are open surgery, laser surgery, and the use of a circumcision stapler. Each one works by cutting off circulation to the foreskin to prevent bleeding when the doctor cuts the foreskin. The procedure takes about 15 to 30 minutes. 
            </p>
            <p>
            For those seeking a Circumcision Surgeon In Navi Mumbai, it is important to choose a qualified professional who has experience in performing circumcisions. Consulting with a Circumcision Surgeon In Navi Mumbai can help ensure that the procedure is done safely and effectively. Ultimately, the decision to circumcise should be made after careful consideration and discussion with a trusted Circumcision Surgeon In Navi Mumbai. 
            </p>
          </div>
        </div>
      </div>
      {/* /card */}
    </div>
  </div>
  <br />
  <br />

<Footer/>
</>

  )
}

export default Circumsion
