import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import advance from '../Components/Images/advance.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Advance() {
  useEffect(()=>{
    document.title="Best Piles Treatment In kopar khairane | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Experience relief with the best piles treatment in kopar khairane under the expert care of Dr. Abhijit Bagul. Restore comfort today."
    )
    
  })
  return (
      <>
  <div className="page-wraper">
    <div
      className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
      style={{ backgroundImage: `url(${bannerimage})` }}
    >
      <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">Best Piles Treatment In kopar khairane </h1>
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Services</li>
              <li>Advanced Laser Proctology</li>
            </ul>
          </div>
          {/* Breadcrumb row END */}
        </div>
      </div>
    </div>
    {/* inner page banner END */}
    {/* Main Slider */}
    <div
      className="section-full bg-white content-inner-2 "
      style={{ backgroundColor: "black" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h2>Laser Proctology</h2>
            <img
              src={advance}
              alt="advanced laser proctoloy treatment in navi mumbai"
              className="img-thumbnail"
              width="100%"
            />
            <br />
            <br />
            <p>
            Laser proctology is the surgical treatment of diseases of the colon, rectum, and anus using a laser. Common conditions treated with laser proctology include hemorrhoids, fissures, fistulas, pilonidal sinus, and polyps. The technique is increasingly being used to treat piles in both women and men. 
            </p>
            <p>
            Laser (Light Amplification by Stimulated Radiation) is a high-energy light beam. This light beam is used to burn or cut the abnormality that necessitated the surgery. Currently, laser techniques are very advanced and much safer; they leave no scars, are bloodless, and less painful. They also cause fewer complications. 
            </p>
            <h3>Laser Surgery Vs Conventional Surgery</h3>
            <p>
            Laser surgery is an outpatient or daycare procedure, offering many advantages over conventional surgery. For example, the laser technique not only treats hemorrhoids more effectively but also reduces postoperative pain. It improves symptoms after surgeries that were not meant to completely cure the patient. Similar results have been seen in patients with external thrombosis, severe anal spasms, fissures, fistulas, varicose veins, and sentinel tags. Specific advantages of laser surgery include: 
            </p>
            <ul className="padd-l">
              <li>Greater surgical precision</li>
              <li>No need for general anesthesia</li>
              <li>Faster surgeries</li>
              <li>Daycare procedure </li>
              <li>Back to routine in 3-5 days</li>
              <li>No sutures and no scars</li>
              <li>Fast recoveries </li>
              <li>No blood loss during surgery </li>
              {/* <li>Less loss of during surgery</li> */}
              <li>Minimal postoperative pain</li>
              <li> Low risk of infection</li>
              <li>Low risk of prolapse or rectal stenosis</li>
              <li>Laser surgery patients look better</li>
              <li>
                Anal sphincter action is well preserved (no chances of
                incontinence).
              </li>
              <li>Least rates of recurrence</li>
              <li>Fewer visits to the doctor after surgery</li>
              <li>Higher success rates</li>
            </ul>
            <h3>What to Expect With Laser Proctology Surgeries? </h3>
            <p>
              <b>Before the Surgery:</b> Your doctor or surgeon will explain the entire procedure to you and the expected results. The medical team will give you specific instructions on what to do and what not to do before the surgery. They will also conduct some pre-operative examinations. 
            </p>
            <p>
              <b>During Surgery:</b> The surgery will be carried out by a team including the anesthesia provider, the surgeon, and support staff. The procedure will be conducted under regional anesthesia. Depending on the condition, the operation lasts from a few minutes to one hour. 
            </p>
            <p>
              <b>After Surgery:</b> Laser proctology surgeries are usually conducted as outpatient procedures, but some patients may be monitored overnight. The time of recovery varies from patient to patient, but you can expect to resume normal activities in one or two days. For effective treatment and a successful recovery, consider consulting the Best Piles Treatment In Kopar Khairane. This approach ensures you receive the best care tailored to your needs, making your journey to recovery smoother. 
            </p>
            <p>
            If you have any concerns or need further information, reaching out to specialists who provide the Best Piles Treatment In Kopar Khairane can help you make informed decisions regarding your health. Your well-being is the top priority, and with advancements in laser proctology, you can expect a more comfortable and effective treatment experience. Make sure to seek the Best Piles Treatment In Kopar Khairane for a positive outcome. 
            </p>
          </div>
        </div>
      </div>
      {/* /card */}
    </div>
  </div>
  <br />
  <br />
 
<Footer/>

    </>
  )
}

export default Advance
