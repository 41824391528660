import React from 'react'
import Navbarr from '../Header/Navbarr'
import AboutHome from '../AboutHome/AboutHome'
import SecAbout from '../SecAbout/SecAbout'
import WhyChoose from '../WhyChoose/WhyChoose'
import Speciality from '../Speciality/Speciality'
import BestSurgen from '../BestSurgen/BestSurgen'
import Branches from '../Branches/Branches'
import Testimonial from '../Testimonial/Testimonial'
import Footer from '../Footer/Footer'
import Banner from '../Banner/Banner'
import Counter from '../Counter/Counter'

function Home() {
  return (
    <>
      {/* banner */}
      <Banner/>
      <AboutHome/>
      {/* <SecAbout/> */}
      <WhyChoose/>
      <Counter/>
      <Speciality/>
      <BestSurgen/>
      <Branches/>
      <Testimonial/>
      <Footer/>
    </>
  )
}

export default Home
