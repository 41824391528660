import React from 'react'
import Footer from '../Components/Footer/Footer'
import { useEffect } from 'react';
import bannerimage from '../Components/Images/banner-image.png'
function GallerySurgical() {
  useEffect(()=>{
    document.title="Fissure Surgeon In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul, proficient Fissure Surgeon in Navi Mumbai, offers expert care for anal fissures with precision and compassion."
    )
    
  })
  return (
    <>
      <div
  className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
  style={{ backgroundImage: `url(${bannerimage})` }}
>
  <div className="container">
    <div className="dlab-bnr-inr-entry">
      <h1 className="text-white">Fissure Surgeon In Navi Mumbai </h1>
      {/* Breadcrumb row */}
      <div className="breadcrumb-row">
        <ul className="list-inline">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Videos</li>
        </ul>
      </div>
      {/* Breadcrumb row END */}
    </div>
  </div>
</div>

<section className='faq-box'>
  <div className="container">
  <div className="container page-top">
  <div className="row">
    <div className="col-lg-6 col-md-6 col-xs-6 thumb">
      <iframe
        width="100%"
        height={400}
        src="https://www.youtube.com/embed/GGHbwp2n4OI"
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen=""
      />
    </div>
  </div>
  <div style={{display:'none'}}>
  <h4 className='mt-5'>Fissure Surgeon in Navi Mumbai – Expert Care for Anal Fissures </h4>
  <p>If you're seeking relief from the pain and discomfort caused by anal fissures, you need an experienced fissure surgeon in Navi Mumbai. Anal fissures, small tears in the lining of the anus, can cause severe pain and discomfort, especially during bowel movements. Finding a skilled fissure surgeon who understands the complexities of this condition is crucial for effective treatment and long-term relief. 
  </p>
  <h4>What is an Anal Fissure? </h4>
  <p>An anal fissure is a common condition where a small tear occurs in the anal canal, often leading to sharp pain, bleeding, and itching. This condition can be triggered by various factors, including chronic constipation, diarrhea, childbirth, or straining during bowel movements. In some cases, fissures can heal on their own, but when symptoms persist or worsen, consulting a fissure surgeon in Navi Mumbai is essential for proper diagnosis and treatment. 
  </p>
  <h4>Why Choose a Fissure Surgeon in Navi Mumbai?  </h4>
  <p>A fissure surgeon specializes in diagnosing and treating anal fissures using the latest medical techniques. Whether the fissure is acute or chronic, an expert surgeon can offer customized treatment plans, including medication, lifestyle changes, or surgical intervention. 
  </p>
  <p>At our clinic in Navi Mumbai, we provide advanced treatment options for fissures, ensuring quick recovery and minimal discomfort. Our surgeons use cutting-edge procedures like lateral internal sphincterotomy, a minimally invasive surgery that provides long-lasting relief from fissures. </p>
  <b>Personalized Treatment and Care </b>
  <p>Our fissure surgeons in Navi Mumbai understand the sensitivity of this condition. We offer a compassionate, patient-centric approach, providing personalized care to ensure comfort and peace of mind. Our goal is to help you regain control of your life by offering expert treatment for anal fissures. </p>
  <b>Book an Appointment Today </b>
  <p>If you're suffering from anal fissures, don't wait for the pain to worsen. Contact our experienced fissure surgeon in Navi Mumbai for a thorough consultation and the best treatment options tailored to your needs. Take the first step toward a pain-free life today! </p>
  </div>
</div>
  </div>
 </section>

 <Footer/>
    </>
  )
}

export default GallerySurgical
