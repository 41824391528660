import React from 'react'
import Footer from '../Components/Footer/Footer'
import Accordion from 'react-bootstrap/Accordion';
import { useEffect } from 'react';
import stitchlesshernia from '../Components/Images/stitch-less-hernia.jpg'
import bannerimage from '../Components/Images/banner-image.png'
function Stitch() {
  useEffect(()=>{
    document.title="Best Doctor For Hernia Surgery In Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul is renowned as the best doctor for hernia surgery in Mumbai, offering expertise and compassionate care."
    )
    
  })
  return (
    <>
    <div className="page-wraper">
      <div
        className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
        style={{ backgroundImage: `url(${bannerimage})` }}
      >
        <div className="container">
          <div className="dlab-bnr-inr-entry">
            <h1 className="text-white">Best Doctor For Hernia Surgery In Mumbai </h1>
            {/* Breadcrumb row */}
            <div className="breadcrumb-row">
              <ul className="list-inline">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Services</li>
                <li>Stitch-less Hernia</li>
              </ul>
            </div>
            {/* Breadcrumb row END */}
          </div>
        </div>
      </div>
      {/* inner page banner END */}
      {/* Main Slider */}
      <div
        className="section-full bg-white content-inner-2 "
        style={{ backgroundColor: "black" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h2>Stitch-less Hernia</h2>
              <img
                src={stitchlesshernia}
                alt="stitchless hernia in navi mumbai"
                className="img-thumbnail"
                width="100%"
              />
              <br />
              <br />
              <p>
                Important things you need to know about hernia &amp; treatment
              </p>
              <div className="m-tb20">
                <div className="accordion no-gap" id="accordion1">
                  <div className="panel">
                    <div className="acod-head">
                      <h6 className="acod-title">
                        <a
                          href="#"
                          data-toggle="collapse"
                          data-target="#faq1"
                          className=""
                          aria-expanded="true"
                        >
                          What is hernia?
                        </a>
                      </h6>
                    </div>
                    <div
                      id="faq1"
                      className="acod-body collapse show"
                      data-parent="#accordion1"
                      style={{}}
                    >
                      <div className="acod-content">
                      Hernia occurs when the intestine bulges out due to a weakness of the connective tissue of the abdominal wall around the groin, navel, or abdomen area. A visit to our Speciality Hernia Care Clinic or to your physician is essential to diagnose the hernia. If you're seeking the Best Doctor For Hernia Surgery in Mumbai, this is the right place to start. 
                        <br />
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <img
                              src="img/services/inguinal-hernia.png"
                              alt="inguinal hernia"
                              className="img-thumbnail"
                              width="100%"
                            />
                          </div>
                          <div className="col-md-4">
                            <img
                              src="img/services/hernia.png"
                              alt="hernia"
                              className="img-thumbnail"
                              width="100%"
                            />
                          </div>
                          <div className="col-md-4">
                            <img
                              src="img/services/hernia-types.png"
                              alt="different types of hernia"
                              className="img-thumbnail"
                              width="100%"
                            />
                          </div>
                        </div>
                        <br />
                        <center>
                          <p>Types of Hernia</p>
                        </center>
                      </div>
                    </div>
                  </div>
                  <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>How do I know if I have Hernia? </Accordion.Header>
        <Accordion.Body>
        Painful bulge is one of the most common symptoms of a hernia. If you have a swelling on the abdomen or groin or a swelling on a previously operated site, you could be having a HERNIA. Consulting the Best Doctor For Hernia Surgery in Mumbai can help confirm the diagnosis. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How does a Hernia feel like? </Accordion.Header>
        <Accordion.Body>
        Hernia starts as a new bulge or lump in the groin or in the abdominal area. The lump increases in size on standing or coughing and decreases on lying down. At first, there is a dull ache at the hernia site. If left untreated, the small marble-size painless hernia progresses to coconut-size. Further neglect will lead to a painful hernia as the intestine gets trapped and the hernia doesn’t reduce in size on lying down (i.e., intestines do not return to the abdominal area). When the intestine gets obstructed in the hernia defect, there is chronic unbearable pain, extreme discomfort, inability to pass bowel gas, nausea, and vomiting. A more serious complication is when the intestine loop becomes 'strangulated' in the hernia; the blood supply is cut off, resulting in bowel tissue death (necrosis), leading to a life-threatening condition called septicemia, which requires emergency surgery. If you're concerned about these symptoms, seeking the Best Doctor For Hernia Surgery in Mumbai is crucial. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What are the causes of hernia? </Accordion.Header>
        <Accordion.Body>
        Causes of hernia vary from congenital (at birth), hereditary, obesity, pregnancy, previous surgery, nutritional deficiency, lifting heavy objects, straining due to constipation, to chronic cough. Anybody can get hernia—from infants to the elderly; though men pose a higher risk. It’s advisable to consult with the Best Doctor For Hernia Surgery in Mumbai if you suspect any of these risk factors. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What is the treatment of Hernia? </Accordion.Header>
        <Accordion.Body>
        Surgery is the only treatment for hernia. Studies have proven that synthetic mesh used to cover the hernia hole and stitched all over at the hernia site helps prevent hernias from recurring. You should discuss your options with the Best Doctor For Hernia Surgery in Mumbai. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Are there any other Treatment of a Hernia other than Surgery? </Accordion.Header>
        <Accordion.Body>
        Some patients wear a truss (hernia belt) which places pressure over the bulge. This is ineffective and does not repair the hernia and usually delays surgery. There are no medications that can repair a hernia. Surgery is the only solution to repair the hernia, so consulting the Best Doctor For Hernia Surgery in Mumbai is essential. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>What are the options for Hernia Surgery? </Accordion.Header>
        <Accordion.Body>
<ul>
  <li>
    <b>Open Surgery </b>
  <br/>
  Most hernia surgeries are done through open surgery where an incision of 10-12 cm is taken above the hernia. FLAT Mesh is then placed in a manner that repairs the hernia. This Mesh needs to be stitched around the site of the hernia. The use of mesh has reduced the incidence of recurrence but may have led to an increase in the incidence of chronic pain. Open surgery is cost-effective. 
  </li>
  <li>
    <b>Laparoscopic Surgery </b>
  <br/>
  Laparoscopic surgery is another repair option associated with risks such as the use of sharp laparoscopic instruments and trocars, the need for General Anesthesia, and selection of a skilled laparoscopic surgeon. Studies show that patients undergoing laparoscopic surgery have a quicker recovery and less short-term pain than with open repair. However, in either surgery, small nerves can be irritated by the procedure, the mesh, or by stitches/tacks used to hold the mesh against the abdominal wall. Long-term pain may be prevalent in either method. 
  </li>
  <li>
    <b>3-D Dynamic Day Surgery </b>
  <br/>
  3-D Hernia Surgery is a modern technique that involves repair through uniquely engineered 3-D implants. Under local anesthesia, the surgeon makes a minimal incision of about 3-4 cm at the hernia and exposes the hole in the muscle. With the help of a special delivery device, the 3-D Implant is introduced into the hernia hole, where it springs open into the hole and grips it without the need for any sutures, stitches, staples, or tacks. The back disk of the implant repairs the hernia from behind, giving the advantages of laparoscopic repair. The entire surgery takes less than 15-20 minutes. The patient can walk out to recovery in about 6 hours and be discharged. The surgery is generally done as day surgery, meaning that following the surgery, the patient is discharged home shortly after. For the best results, find the Best Doctor For Hernia Surgery in Mumbai. 
  </li>
</ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>What is the most common complication of Hernia Surgery? </Accordion.Header>
        <Accordion.Body>
        The most common complication of hernia surgery is chronic groin pain, occurring in about 30% of patients. This chronic groin pain varies from slight discomfort to severe disabling pain, which can interfere with normal activities and might last a lifetime in extreme cases. Several factors may cause chronic groin pain. Flat meshes placed in the anterior space (above hernia) can cause traction and trauma to the nerves present in the groin. Fixating or suturing the mesh to the abdominal muscle also causes pain. Spermatic cord compression by meshes and stitches leads to pain. Consulting with the Best Doctor For Hernia Surgery in Mumbai can help manage these risks. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header>How does 3-D Dynamic Hernia Repair with ProFlor resolve the issues of chronic groin pain? </Accordion.Header>
        <Accordion.Body>
        ProFlor is the only hernia implant designed to address chronic groin pain following hernia surgery. It consists of a dynamic core attached to a back disk placed in the posterior space (behind the hernia hole), avoiding placement of the mesh in the anterior space where the nerves are located. It requires minimal dissection in the anterior space, thus avoiding trauma to the nerves, and there is no fixation involved. ProFlor is the ONLY IMPLANT known to preserve the spermatic cord in its petals without compression. 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header>How does 3-D Dynamic Repairs Technique Improve Quality of Life in Hernia patients? </Accordion.Header>
        <Accordion.Body>
        Patients have confirmed no pain post-surgery and long-term through evidence-based questionnaires like the Carolinas Comfort Scale. The scale assesses quality of life in patients with questions regarding whether they feel a "sensation of mesh," pain, or movement limitations during daily activities. The incidence of recurrence is almost none compared to traditional open surgery (8-15%) or laparoscopic surgery (4-11%) that uses traditional mesh and its tacks. Most importantly, the 3-D surgery outcomes lead to freedom from hernia, pain, and discomfort. With the advent of 3-D dynamic suture-less mesh, the advantages of laparoscopy can be provided with a smaller incision and cost-effectiveness for patients. 
        <br/><br/>For anyone seeking the Best Doctor For Hernia Surgery in Mumbai, the focus should be on finding a skilled and experienced surgeon to ensure the best possible outcomes. 
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /card */}
      </div>
    </div>
    <br />
    <br />
   
   <Footer/>
  </>
  
  )
}

export default Stitch
