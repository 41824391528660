import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../Components/Footer/Footer';
import { useEffect } from 'react';
import bannerimage from '../Components/Images/banner-image.png'
function Faq() {
  useEffect(()=>{
    document.title="Best Hernia Surgeon In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul, renowned as the Best Hernia Surgeon in Navi Mumbai, offers exceptional surgical expertise with precision and care."
    )
    
  })
  return (
    <>
      <div
  className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
  style={{ backgroundImage: `url(${bannerimage})` }}
>
  <div className="container">
    <div className="dlab-bnr-inr-entry">
      <h1 className="text-white">Best Hernia Surgeon In Navi Mumbai</h1>
      {/* Breadcrumb row */}
      <div className="breadcrumb-row">
        <ul className="list-inline">
          <li>
            <a href="/">Home</a>
          </li>
          <li>FAQ's</li>
        </ul>
      </div>
      {/* Breadcrumb row END */}


      
    </div>
  </div>
</div>
   <section className='faq-box'>
    <div className="container mb-5">
    <h3 className='faq-head'>Best Hernia Surgeon in Navi Mumbai – Expert Hernia Treatment and Care </h3>
    <p>If you're seeking the best hernia surgeon in Navi Mumbai, you’ve come to the right place. Hernias, though common, require specialized care, and finding an experienced and trusted surgeon is essential to ensure a safe and effective treatment. Hernia repair surgery is a common procedure, but the key to a successful outcome lies in the expertise of your surgeon. </p>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
        
        What is a Hernia? 
        </Accordion.Header>
        <Accordion.Body>
        <div
  id="collapseOne21"
  className="collapse show"
  role="tabpanel"
  aria-labelledby="headingOne21"
  data-parent="#accordion2"
>
  <div className="panel-body">
    <p>
      {" "}
      A hernia occurs when an organ or tissue pushes through a weak spot in the muscle or connective tissue. Common types of hernias include inguinal (groin), umbilical (belly button), and incisional (resulting from previous surgery). If left untreated, hernias can lead to complications such as pain, discomfort, or more severe medical issues like bowel obstruction. 
    </p>
    {/* <br />
    <ul className="padd-l">
      <li> Less post-operative pain </li>
      <li> Faster recovery </li>
      <li> Short hospital stay </li>
      <li>
        {" "}
        Less post-operative complications like wound infection and adhesion
      </li>
      <li> Cost-effective in working group </li>
    </ul> */}
  </div>
</div>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
        Why Choose the Best Hernia Surgeon in Navi Mumbai?        
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    The best hernia surgeon in Navi Mumbai not only diagnoses and treats hernias but also uses advanced techniques to ensure minimal discomfort and quick recovery. Opting for a highly skilled surgeon ensures personalized care tailored to your condition, as well as access to the latest medical technology and surgical procedures. 
    </p>
    <p>
    Our expert surgeons are well-versed in both open surgery and laparoscopic hernia repair, allowing them to recommend the most suitable treatment for your condition. With a focus on patient safety and comfort, they aim to provide effective treatment with minimal recovery time. 
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
        Advanced Hernia Treatment in Navi Mumbai 
        </Accordion.Header>
        <Accordion.Body>
        <div className="panel panel-default mb-3">
  <div className="panel-heading" role="tab" id="headingTwo2">
    <h3 className="panel-title">
      <a
        className="collapsed"
        role="button"
        data-toggle="collapse"
        data-parent="#accordion2"
        href="#collapseTwo2"
        aria-expanded="false"
        aria-controls="collapseTwo"
      >
        {/* What are the complications of laparoscopic appendectomy? */}
      </a>
    </h3>
  </div>

    <div className="panel-body">
      <p>
      Our hernia surgeons in Navi Mumbai specialize in minimally invasive laparoscopic surgery, which offers numerous benefits, such as: 
      </p>
      <br />
      <ul className="padd-l">
        <li>- Smaller incisions and less scarring </li>
        <li>- Reduced pain post-surgery </li>
        <li>- Faster recovery time </li>
        {/* <li>Visceral Injury</li> */}
      </ul>
      <p>
      Whether it’s an inguinal, femoral, or hiatal hernia, our team is equipped to handle all types of hernias with precision and care. With a commitment to using cutting-edge techniques, you can trust that your treatment will be safe, effective, and tailored to your specific needs. 
      </p>
      <p>
      Book your consultation with the best hernia surgeon in Navi Mumbai today for expert diagnosis and care. Your health is in good hands! 
      </p>
      {/* <p></p> */}
      {/* <br />
      <ul className="padd-l">
        <li>Intra-abdominal abscess</li>
        <li>Hernia</li>
      </ul> */}
    </div>
  </div>


        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>


    {/* Breast Lump */}

    {/* <div className="container">
    <h3 className='faq-head'>Breast Lump</h3>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="3">
        <Accordion.Header>
        
        Is a lumpectomy considered major surgery?
        </Accordion.Header>
        <Accordion.Body>
        <div
  id="collapseOne21"
  className="collapse show"
  role="tabpanel"
  aria-labelledby="headingOne21"
  data-parent="#accordion2"
>
  <div className="panel-body">
    <p>
      {" "}
      A lumpectomy is a common but major surgery with significant risks and potential complications. You may have more effective treatment options for your type and stage of breast cancer. You may also have less invasive treatment options for noncancerous tumors.
    </p>
  
   
  </div>
</div>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
        How quickly does the result show? Circumcision
       
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    Healing time after surgery can range anywhere from a few days to a week. After a lumpectomy without a lymph node biopsy, you're likely to feel well enough to return to work after two or three days. You can usually resume normal physical activities, like going to the gym, after one week.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
    </div> */}

    {/* Circumcision */}


    {/* <div className="container">
    <h3 className='faq-head'>Circumcision</h3>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="3">
        <Accordion.Header>
        
        How long does it take to recover from a circumcision?
        </Accordion.Header>
        <Accordion.Body>
        <div
  id="collapseOne21"
  className="collapse show"
  role="tabpanel"
  aria-labelledby="headingOne21"
  data-parent="#accordion2"
>
  <div className="panel-body">
    <p>
    It usually takes at least 10 days for your penis to heal after circumcision. You'll probably be advised to take at least 1 week off work to recover.
    </p>
  
   
  </div>
</div>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
        Are circumcisions painful?
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    Circumcision can be done at any age. Traditionally, the most common time to do it is soon after your baby is born or within the first month of life. Because the process is painful, a local anesthetic is used to numb the area, and the surgery is performed while the baby is still awake.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
    </div> */}

    {/* Gall Bladder Removal Surgery */}


    {/* <div className="container">
    <h3 className='faq-head'>Gall Bladder Removal Surgery</h3>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="5">
        <Accordion.Header>
        
        How long does gallbladder surgery take?
        </Accordion.Header>
        <Accordion.Body>
        <div
  id="collapseOne21"
  className="collapse show"
  role="tabpanel"
  aria-labelledby="headingOne21"
  data-parent="#accordion2"
>
  <div className="panel-body">
    <p>
    Laparoscopic cholecystectomy takes one or two hours.
    </p>
  
   
  </div>
</div>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>
        How long is recovery from laparoscopic gallbladder removal?
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    Most people can return to their normal activities in 7 to 10 days. People who have laparoscopic gallbladder surgery are sore for about a week. But in 2 to 3 weeks they have much less discomfort than people who have open surgery.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
      <Accordion.Item eventKey="7">
        <Accordion.Header>
        How long does the pain last after laparoscopic gallbladder surgery?
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    You may also feel pain in one or both shoulders. This pain comes from the gas still left in your belly after the surgery. The pain should ease over several days to a week.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
    </div> */}

    {/* Laser surgery for Piles */}

    {/* <div className="container">
    <h3 className='faq-head'>Laser surgery for Piles</h3>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="8">
        <Accordion.Header>
        
        Is laser surgery good for piles?
        </Accordion.Header>
        <Accordion.Body>
        <div
  id="collapseOne21"
  className="collapse show"
  role="tabpanel"
  aria-labelledby="headingOne21"
  data-parent="#accordion2"
>
  <div className="panel-body">
    <p>
    Laser surgery or laser therapy is a day-care procedure that offers several advantages over traditional surgery. Compared to banding surgery, laser effectively treats hemorrhoids, improves symptoms and reduces post-operative pain.
    </p>
  
   
  </div>
</div>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>
        What are the advantages of using laser treatment for the treatment of fissure?
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    Fissures mostly don’t need excessive treatments, but with treatment, it can heal faster and not become a complicated case. Doctors may prescribe stool softeners, ointments, and other medicines. Your doctor will also help you with a diet chart in order to avoid this condition in the future.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
      <Accordion.Item eventKey="10">
        <Accordion.Header>
        Is laser treatment for fissure painful?
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    It is a daycare procedure, which can even be conducted within 30 minutes. After this treatment, the patient is discharged on the same day. Radical fiber is used to treat fissures. With no side effects and no pain, today, laser treatments are preferred by many.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
      <Accordion.Item eventKey="11">
        <Accordion.Header>
        Is laser surgery good for piles?
        </Accordion.Header>
        <Accordion.Body>

  <div className="panel-body">
    <p>
    Laser surgery or laser therapy is a day-care procedure that offers several advantages over traditional surgery. Compared to banding surgery, laser effectively treats hemorrhoids, improves symptoms and reduces post-operative pain.
    </p>
  </div>
        </Accordion.Body>
      </Accordion.Item> 
    </Accordion>
    </div>
 */}

  
   </section>
   <Footer/>
    </>
  )
}

export default Faq
