import React from 'react'
import consultingbackground from '../Images/consulting-h-background.jpg'
import mpcthospitallogo from '../Images/mpct-hospital-logo.png'
import reliancehospitallogo from  '../Images/reliance-hospital-logo.png'
import saisnehdeephospitallogo from '../Images/sai -snehdeep-hospital-logo.jpg'
import fortishiranandanihospitallogo from '../Images/fortis-hiranandani-hospital-logo.png'
import DYPATILhospitallogo from '../Images/d-y-patil-hospital-logo.png'
function Branches() {
  return (
    <>
      <div
  className="section-full content-inner-2"
  style={{
    backgroundImage:
      `url(${consultingbackground})`,
    backgroundPosition: "bottom",
    backgroundSize: "cover"
  }}
>
  <div className="container">
    <div className="section-head text-black text-center">
      <h2 className="text-primary m-b10">
        <span style={{ color: "black" }}>CONSULTING HOSPITALS</span>
      </h2>
    </div>
    <div className="row">
      <div className="item p-a5 col-md-4">
        <div className="testimonial-9">
          <center>
            <img
              src={mpcthospitallogo}
              alt="MPCT Hospital"
              style={{ width: 220 }}
            />
          </center>
          <h6>MPCT Hospital</h6>
          <div className="testimonial-text">
            <p>
              <i className="fa fa-phone" /> 022 2775 0000
            </p>
            <p>
              <span>
                <i className="fa fa-map-marker" />{" "}
              </span>{" "}
              C7, Budhyadev Mandir Marg, Sector 4, Sanpada, Navi Mumbai,
              Maharashtra 400705
            </p>
          </div>
        </div>
      </div>
      <div className="item p-a5 col-md-4">
        <div className="testimonial-9">
          <center>
            <img
              src={reliancehospitallogo}
              alt="Reliance Hospital"
              style={{ width: 220 }}
            />
          </center>
          <h6>Reliance Hospital</h6>
          <div className="testimonial-text">
            <p>
              <i className="fa fa-phone" /> 022 3966 6666
            </p>
            <p>
              <span>
                <i className="fa fa-map-marker" />{" "}
              </span>{" "}
              X8 &amp; X8/1, Thane - Belapur Road Opp Kopar Khairane Station,
              Navi Mumbai, Maharashtra 400710
            </p>
          </div>
        </div>
      </div>
      <div className="item p-a5 col-md-4">
        <div className="testimonial-9">
          <center>
            <img
              src={saisnehdeephospitallogo}
              alt="Sai Snehdeep Hospital"
              style={{ width: 220 }}
            />
          </center>
          <h6>Sai Snehdeep Hospital</h6>
          <div className="testimonial-text">
            <p>
              <i className="fa fa-phone" /> 7400 429 057 / 961 916 9203
            </p>
            <p>
              <span>
                <i className="fa fa-map-marker" />{" "}
              </span>{" "}
              Plot 12-13, Sector 20, Kopar Khairane, Navi Mumbai, Maharashtra
              400709
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="item p-a5 col-md-4">
        {" "}
        <br />
        <div className="testimonial-9">
          <center>
            <img
              src={fortishiranandanihospitallogo}
              alt="Fortis Hiranandani Hospital"
              style={{ width: 189 }}
            />
          </center>
          <h6>Fortis Hiranandani Hospital</h6>
          <div className="testimonial-text">
            <p>
              <i className="fa fa-phone" /> 022 3919 9222
            </p>
            <p>
              <span>
                <i className="fa fa-map-marker" />{" "}
              </span>
              Plot No. 28, Juhu Chowpatty Marg, Juhu Nagar, Sector 10A, Vashi,
              Navi Mumbai, Maharashtra 400703
            </p>
          </div>
        </div>
      </div>
      {/*<div class="item p-a5 col-md-4">	<br>*/}
      {/*	<div class="testimonial-9">*/}
      {/*		<center><img src="img/testimonial-logo/lotus-multispeciality-hospital-icu.png" alt="" style="width: 186px;"></center>*/}
      {/*		<h6>Lotus Multispeciality Hospital & ICU</h6>*/}
      {/*		<div class="testimonial-text">*/}
      {/*			<p><i class="fa fa-phone"></i>  022 2789 2020</p>*/}
      {/*			<p><span><i class="fa fa-map-marker"></i> </span> Balaji Sadan, 20D, Prabodhankar Thackeray Marg, Juhu Nagar, Sector 15, Vashi, Navi Mumbai, Maharashtra 400703</p>*/}
      {/*		</div>*/}
      {/*	</div>*/}
      {/*</div>*/}
      <div className="item p-a5 col-md-4">
        {" "}
        <br />
        <div className="testimonial-9">
          <center>
            <img
              src={DYPATILhospitallogo}
              alt="D Y Patil Hospital"
              style={{ width: 186 }}
            />
          </center>
          <h6>D Y Patil Hospital</h6>
          <div className="testimonial-text">
            <p>
              <i className="fa fa-phone" /> 022 2773 5901
            </p>
            <p>
              <span>
                <i className="fa fa-map-marker" />{" "}
              </span>{" "}
              Sector 5, Nerul, Navi Mumbai, Maharashtra 400706
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default Branches
