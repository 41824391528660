import React from 'react'
import "./testi.css"
import tone from '../Images/tone.png'
import ttwo from '../Images/ttwo.png'
import tthree from '../Images/tthree.png'
import tfour from '../Images/tfour.png'

function Testimonial() {
  return (
    <>
    <section>
      <div className="container">
      <section className="sec-testimonial">
  <div className="container">
    <h2 className="text-primary m-b10 text-center" style={{ marginTop: 30 }}>
      Testimonials
    </h2>
    <div className="row">
      <div className="col-md-12 text-center">
        <div className="elfsight-app-76555559-859a-4edf-aa28-607ab47926c0" />
      </div>
    </div>
  </div>
</section>

      </div>
    </section>
    <section
  className="section-full  content-inner-2 "
  style={{ background: "#2acb35" }}
>
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <h2 className=" m-b10" style={{ color: "white" }}>
          Healthcare Facilities
        </h2>
        <p style={{ color: "white" }}>
          Dr. Baguls Proctology Clinic is one of the best integrated advanced
          proctology treatment clinics in Navi Mumbai, committed to offering the
          community freedom from diseases related to the colon, rectum, and
          anus.
        </p>
        <h5 style={{ color: "white" }}>
          {" "}
          <i className="fa fa-phone" /> +91-9820144713
        </h5>
      </div>
      <div className="col-md-8">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 text-center">
                <img src={tone} />
              </div>
              <div className="col-md-8">
                <h5 style={{ color: "white" }}>Quick Appointment</h5>
                <p style={{ color: "white" }}>
                  We provide a quick and hassle-free appointment with our
                  doctor. We are happy to help you in time of your emergency
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 text-center">
                <img src={ttwo} />
              </div>
              <div className="col-md-8">
                <h5 style={{ color: "white" }}>Cashless Faclity</h5>
                <p style={{ color: "white" }}>
                  Easy, Quick and Efficient Cashless Facility. During emergency
                  the first place you rush to should be hospital not bank.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 text-center">
                <img src={tthree} />
              </div>
              <div className="col-md-8">
                <h5 style={{ color: "white" }}>24 Hrs Availability </h5>
                <p style={{ color: "white" }}>
                  Our doctor is available 24/7 so as to help and guide patients.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 text-center">
                <img src={tfour} />
              </div>
              <div className="col-md-8">
                <h5 style={{ color: "white" }}>Online Consultation </h5>
                <p style={{ color: "white" }}>
                  Free Online Consultation is now available right from your
                  Home!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Testimonial

