import React from 'react'
import { Image} from 'antd';
import { useEffect } from 'react';
import smone from '../Components/Images/smone.png'
import smtwo from '../Components/Images/smtwo.jpg'
import smthree from '../Components/Images/smthree.png'
import smfour from '../Components/Images/smfour.png'
import smfive from '../Components/Images/smfive.png'
import smsix from '../Components/Images/smsix.png'
import smseven from '../Components/Images/smseven.png'
import smeight from '../Components/Images/smeight.png'
import smnine from '../Components/Images/smnine.png'
import smten from '../Components/Images/smten.jpg'
import smeleven from '../Components/Images/smeleven.jpg'
import smtwelve from '../Components/Images/smtwelve.jpg'
import smthirteen from '../Components/Images/smthirteen.jpg'
import smfourteen from '../Components/Images/smfourteen.jpg'
import bannerimage from '../Components/Images/banner-image.png'
import Footer from '../Components/Footer/Footer';

function SurgicalMedia() {
  useEffect(()=>{
    document.title="Best Proctologist In Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Experience relief with Dr. Abhijit Bagul, recognized as the Best Proctologist in Navi Mumbai. Expert care for gastrointestinal health."
    )
    
  })
  return (
    <>

    <p className=' hide-con'>
    The Surgical Archive is a comprehensive repository of historical and contemporary surgical knowledge, ideal for anyone seeking the best proctologist in Navi Mumbai. It features meticulously curated articles, case studies, and rare surgical manuscripts, offering insights that can help medical professionals and researchers enhance their understanding of surgical practices and advancements.


Our collection spans the evolution of surgical techniques, groundbreaking innovations, and biographies of pioneering surgeons, including those recognized as the best proctologist in Navi Mumbai. The archive also includes detailed visual aids, surgical instruments, and procedural recordings. By preserving and sharing this rich heritage, the Surgical Archive aims to inspire and educate future generations of surgeons, fostering a deeper appreciation for the history and progress of surgical science.



    </p>
     
      <div
  className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
  style={{ backgroundImage: `url(${bannerimage})` }}
>
  <div className="container">
    <div className="dlab-bnr-inr-entry">
      <h1 className="text-white">Surgical Archieve</h1>
      {/* Breadcrumb row */}
      <div className="breadcrumb-row">
        <ul className="list-inline">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Best Proctologist In Navi Mumbai </li>
        </ul>
      </div>
      {/* Breadcrumb row END */}
    </div>
  </div>
</div>
  <section>
    <div className="container">
    <div className="container page-top">
      <div className='mt-5' style={{display:'none'}}>
        <p>
        Looking for the best proctologist in Navi Mumbai? When it comes to finding expert care for conditions related to the colon, rectum, and anus, having an experienced proctologist by your side is crucial. At [Clinic Name], we pride ourselves on offering comprehensive, patient-centered care to ensure quick diagnosis, effective treatment, and a smooth recovery process. 
        </p>
        <h4>Why Choose the Best Proctologist in Navi Mumbai? </h4>
        <p>
        Proctology is a specialized field that addresses a wide range of conditions such as hemorrhoids, fissures, fistulas, and colorectal cancers. If left untreated, these conditions can lead to severe discomfort and complications. The best proctologist understands the importance of timely diagnosis and personalized treatment plans to improve your quality of life. 
        </p>
        <p>
        With years of experience and state-of-the-art facilities, our proctologists use the latest medical technologies and minimally invasive procedures to treat both common and complex anorectal conditions. Whether it’s a simple outpatient treatment or a more advanced surgical intervention, you can count on our skilled specialists to deliver exceptional care tailored to your needs. 
        </p>
        <h4>Conditions Treated by Our Proctologist </h4>
        <b>
        Some of the most common conditions treated by our best proctologist in Navi Mumbai include: 
        </b>
        <p>Hemorrhoids: Swollen veins in the lower rectum or anus causing pain, itching, and discomfort. </p>
        <p>Anal Fissures: Small tears in the lining of the anus, often causing severe pain during bowel movements. </p>
        <p>Fistulas: Abnormal connections between the rectum and surrounding tissues, which may lead to infection. </p>
        <p>Pilonidal Sinus: A small hole or tunnel in the skin, often leading to infection or abscess formation. </p>
        <h4>
        Why Dr Baghul? 
        </h4>
        <p>we focus not only on treating the condition but also on providing preventive care and long-term solutions. With a compassionate team, personalized treatment plans, and a commitment to using the latest techniques, we aim to restore your health and well-being. </p>
        <p>For expert care and to consult the best proctologist in Navi Mumbai, schedule an appointment with us today! </p>
      </div>
  <div className="row">
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a >
        <Image
          src={smone}
          className="zoom img-fluid "
          alt="conventional hernia surgery incision with infection"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smtwo}
          className="zoom img-fluid "
          alt="mesh infection treatment after conventional hernia surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smthree}
          className="zoom img-fluid "
          alt="stitchless 3d hernia surgery 2"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smfour}
          className="zoom img-fluid "
          alt="stitchless 3d hernia surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smfive}
          className="zoom img-fluid "
          alt="para umbilical hernia before surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smsix}
          className="zoom img-fluid "
          alt="para umbilical hernia after surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5  thumb">
      <a>
        <Image
          src={smseven}
          className="zoom img-fluid "
          alt="umbilical hernia before surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smeight}
          className="zoom img-fluid "
          alt="umbilical hernia after surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smnine}
          className="zoom img-fluid "
          alt="incisiona lhernia before surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smten}
          className="zoom img-fluid "
          alt="multiple ventral hernia before surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smeleven}
          className="zoom img-fluid "
          alt="recurrent ventral hernia before surgery"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smtwelve}
          className="zoom img-fluid "
          alt="stitchless octomesh incisional"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 mt-5 thumb">
      <a>
        <Image
          src={smthirteen}
          className="zoom img-fluid "
          alt="stitchless octomesh multiple"
        />
      </a>
    </div>
    <div className="col-lg-3 col-md-4 col-xs-6 mt-5 thumb">
      <a>
        <Image
          src={smfourteen}
          className="zoom img-fluid "
          alt="stitchless octomesh recurrent surgery"
        />
      </a>
    </div>
  </div>
</div>

    </div>
  </section>
  <Footer/>
    </>
  )
}

export default SurgicalMedia
