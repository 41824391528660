import React from 'react'
import wcone from '../Images/wcone.jpg'
import wcthree from '../Images/wcthree.png'
import wcfour from '../Images/wcfour.png'
import wcfive from '../Images/wcfive.png'
import wcsix from '../Images/wcsix.png'
import wcseven from '../Images/wcseven.png'

function WhyChoose() {
  return (
    <>
      <div
  className="section-full bg-white content-inner-2 "
  style={{ backgroundColor: "black" }}
>
  <div className="container">
    <div className="row">
      <div className="col-md-5 why-choose-drimg  d-none d-sm-block d-sm-none d-md-block">
        <br />
        <img src={wcone} />
      </div>
      <div className="col-md-7">
        <div className="section-head text-black">
          <h2 className="text-primary m-b10" style={{ marginTop: 30 }}>
            Why Choose Dr. Abhijit Bagul?
          </h2>
          <br />
          <div className="col-md-5 why-choose-drimg   d-block d-sm-none d-none d-sm-block d-md-none">
            <br />
            <img src={wcone} />{" "}
            <br />
          </div>
          <ul className="why-choose-list">
            <li>
              <img src={wcthree} />
              20+ yrs Experience as Gastrointestinal &amp; Laparoscopic Surgeon
              in Navi Mumbai
            </li>
            <li>
              <img src={wcfour} />
              Updated with Advanced Proctology Treatment{" "}
            </li>
            <li>
              <img src={wcfive} />
              Expertise in Minimally Invasive GI Surgery
            </li>
            <li>
              <img src={wcsix} />
              Provide Accurate Diagnosis and Treatment
            </li>
            <li>
              <img src={wcseven}/>
              Focuses on Conservative Treatment (Non-Surgical Treatment) and
              Later recommends Surgical Procedure if needed.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default WhyChoose
