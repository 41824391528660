import React, { useEffect, useState } from 'react';
import Branches from '../Components/Branches/Branches';
import Footer from '../Components/Footer/Footer';
import Accordion from 'react-bootstrap/Accordion';
import bannerimage from '../Components/Images/banner-image.png'
import gastrointestinallaparoscopicsurgeonnavimumbai from '../Components/Images/gastrointestinal-laparoscopic-surgeon-navi-mumbai.jpg'
function About() {
  // State to manage the visibility of the additional paragraphs
  const [showMore, setShowMore] = useState(false);

  // Function to toggle the state
  const toggleReadMore = () => {
    setShowMore(!showMore);
  };
  useEffect(()=>{
    document.title="Best Fistula Surgery Doctor near Navi Mumbai | Dr. Abhijit Bagul";
    document.querySelector("meta[name='description']").setAttribute("content",
      "Dr. Abhijit Bagul, the top-rated specialist for fistula surgeries, offers unparalleled care near Navi Mumbai. Trusted expertise assured."
    )
    
  })

  return (
    <>
      <div className="page-wraper">
        {/* Your existing code */}
        <nav className="navbar navbar-expand-md d-lg-none d-md-none d-block">
          {/* Navbar content */}
        </nav>

        <div
          className="dlab-bnr-inr dlab-bnr-inr overlay-primary"
          style={{ backgroundImage: `url(${bannerimage})` }}
        >
           <div className="container">
        <div className="dlab-bnr-inr-entry">
          <h1 className="text-white">Best Fistula Surgery Doctor near Navi Mumbai  </h1>
          {/* Breadcrumb row */}
          <div className="breadcrumb-row">
            <ul className="list-inline">
              <li>
                <a href="/">Home</a>
              </li>
              <li>About</li>
              {/* <li>Services</li>
              <li>Advanced Laser Proctology</li> */}
            </ul>
          </div>
          {/* Breadcrumb row END */}
        </div>
      </div>
          {/* Banner content */}
        </div>

        <div className="section-full bg-white content-inner-2 " style={{ backgroundColor: "black" }}>
          <div className="container">
            <div className="row">
              <div
                className="col-md-4  d-none d-sm-block d-sm-none d-md-block"
                style={{ marginTop: 0 }}
              >
                <img
                  width={300}
                  height={300}
                  src={gastrointestinallaparoscopicsurgeonnavimumbai}
                  alt="img"
                  className="img-wd"
                />
              </div>
              <div className="col-md-8">
                <div className="section-head text-black">
                  <h2 className="text-primary m-b10" style={{ marginTop: 0 }}>
                    About DR. ABHIJIT BAGUL
                  </h2>
                  <h6 className="m-b10">
                    MS, FIAGES, FMAS, FCPS (Surgery), FICS (USA), FALS
                    <br />
                    Consultant Gastrointestinal &amp; Laproscopic Surgeon
                    <br />
                    Proctologist, Colorectal &amp; Laser Surgeon
                  </h6>
                  <div
                    className="col-md-4 d-block d-sm-none d-none d-sm-block d-md-none"
                    style={{ marginTop: 0 }}
                  >
                    <br />
                    <img
                      width={600}
                      height={600}
                      src="img/gastrointestinal-laparoscopic-surgeon-navi-mumbai.jpg"
                      alt="img"
                      className="img-wd"
                    />
                    <br />
                    <br />
                  </div>
                  <p>
                    Dr. Abhijit G. Bagul, renowned as the Best Fistula Surgery Doctor near Vashi, Navi Mumbai, is a distinguished Proctologist, Colorectal & Laser Surgeon. His impressive educational background includes an MBBS and an MS in Surgery from Sir J.J. Group of Hospitals in Mumbai. Further enhancing his expertise, Dr. Bagul holds a Fellowship in Minimal Access Surgery (FMAS) and has received a prestigious fellowship at the esteemed KEM Hospital in Mumbai, affiliated with the Indian Association of Gastrointestinal Endosurgeons.
                  </p>
                  <p>
                    Dr. Bagul's contributions to medical literature are noteworthy, with two of his study publications being recognized among the world's most exceptional cases. His commitment to advancing medical knowledge and patient care is evident through these accolades. In addition to his academic and clinical responsibilities, Dr. Bagul has demonstrated remarkable dedication to emergency medical services. Notably, he played a crucial role in treating multiple casualties on-site during the Bhuj (Gujarat) earthquake disaster, showcasing his ability to handle critical situations with expertise and compassion.
                  </p>
                  
                  {/* Conditionally render the additional paragraphs */}
                  {showMore && (
                    <>
                      <p>
                        Dr. Bagul's commitment to community service is another testament to his outstanding character. He has been instrumental in setting up numerous surgical camps for patients from low-income or impoverished backgrounds, ensuring that quality medical care is accessible to those in need. His dedication to improving the lives of his patients and his community is truly commendable.
                      </p>
                      <p>
                        Known as the Best Fistula Surgery Doctor near Vashi, Navi Mumbai, Dr. Bagul's reputation is well-deserved. His expertise in proctology, colorectal, and laser surgery, combined with his compassionate approach to patient care, makes him a trusted and respected figure in the medical community. Whether it is through his innovative surgical techniques, his contributions to medical literature, or his commitment to community service, Dr. Abhijit G. Bagul stands out as a leading medical professional dedicated to excellence in patient care.
                      </p>
                    </>
                  )}

                  {/* Toggle button */}
                  <button onClick={toggleReadMore} className="read-more">
                    {showMore ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accod">
        <section>
          <div className="container">
          <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Educational Qualifications</Accordion.Header>
        <Accordion.Body>
        <ul>
          <li>MS, FIAGES, FMAS, FCPS (Surgery),FICS (USA)</li>
          <li>Grant Medical College, Sir JJ Group of Hospital, Mumbai</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
        Professional Experience Summary</Accordion.Header>
        <Accordion.Body>
        Over 18 years of experience in Peripheral Vascular, Gastro intestinal, hepatobiliary, Urology, Onco-Surgery, Minimal Access Surgery, Burns Management, Trauma Care
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Specialty</Accordion.Header>
        <Accordion.Body>
        <ul>
          <li>General Surgery</li>
          <li>GI Surgery</li>
          <li>Laser Fistula</li>
          <li>Stapler Haemorrhoid</li>
          <li>Breast Clinic</li>
          <li>Circumcision</li>
          <li>Basic and Adv Laproscopic surgery</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Research Publications</Accordion.Header>
        <Accordion.Body>
        <ul>
          <li>Gel foam Induced Adhesive Intestinal Obstruction – rare case reported. Volume 4 Issue 1, January 2015 (International Journal of Science and Research)</li>
          <li>Mesh Migration Causing Strangulated Intestinal Obstruction after Umbilical Hernia Repair– rare case reported. (JMSCR Volume 03 Issue 01 Page 3986-3989 January 2015)</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
     
    
   
    </Accordion>
          </div>
        </section>
        </div>

        {/* Remaining content */}
        <Branches />
        <Footer />
      </div>
    </>
  );
}

export default About;
